import React, { PureComponent } from "react"
import PropTypes from "prop-types"
import { connect } from "react-redux"
import { updateSessionStackKey, bumpSessionStackToFirst } from "../actions"
import { axios_instance } from "../util/server"
import { t } from "../intl"
import "./StackSelect.scss"
import moment from "moment"
import CardPreview from "./GUI/CardPreview"
import StateButton from "./GUI/StateButton"
import "./StackPreview.scss"

const Ruby_Date_Format = "YYYY-MM-DD hh:mm:ss Z"

class StackPreview extends PureComponent {
	static propTypes = {
		sessionToken: PropTypes.string.isRequired,
		stackKey: PropTypes.string,
		onSelect: PropTypes.func.isRequired,
		updateSessionStackKey: PropTypes.func.isRequired,
		bumpSessionStackToFirst: PropTypes.func.isRequired,
		placeholder: PropTypes.bool
	}

	state = {
		busy: false,
		waitingEditKey: false
	}
	
	onEditKey () {
		if (!window.confirm(t("stack_name_warning"))) {
			return
		}
		let newKey = prompt(t("stack_name_instructions"), this.props.stackKey.split('-')[0])
		if (!newKey || newKey.length <= 1) {
			return
		}
		newKey = newKey
			.toLowerCase()
			.replace(/ /g, "_")
			.replace(/[^0-9a-z_]/g, "")
		if (newKey !== this.props.stackKey) {
			this.submitKeyChange(newKey)
		}
	}

	submitKeyChange (newKey) {
		this.setState({ waitingEditKey: true })
		axios_instance.post("/stacks/edit_key", {
			session_token: this.props.sessionToken,
			stack_key: this.props.stackKey,
			new_key: newKey
		})
			.then(response => {
				if (response.data.new_key) {
					this.props.bumpSessionStackToFirst(this.props.stackKey)
					this.props.updateSessionStackKey(this.props.stackKey, response.data.new_key)
				}
			})
			.catch(error => {
				if (error.response && error.response.data) {
					alert(t(error.response.data))
				} else {
					console.error(error)
				}
				this.setState({ waitingEditKey: false })
			})
	}

	getPreviewCard (onSelect) {
		if (this.props.placeholder) {
			return <div className="CardPreview clickable">
				<div className="clickable-surface full-size-centered" onClick={this.props.onSelect}>
					<div className="plus">+</div>
				</div>
			</div>
		}
		if (this.props.home_card) {
			return <CardPreview {...this.props.home_card} onClick={onSelect} />
		} else {
			return <CardPreview
				type="details"
				data={{
					title: t("newStack")
				}}
				onClick={onSelect}
			/>
		}
	}

	render () {
		const name = this.props.name || (this.props.stackKey ? this.props.stackKey.split('-')[0] : "")
		const classNames = ['StackPreview']
		if (this.props.placeholder) classNames.push('placeholder')
		if (this.state.busy) classNames.push('busy')
		return <div className={classNames.join(" ")}>
			<div className="preview">
				<div className="glow"></div>
				{ this.getPreviewCard(this.props.onSelect) }
				<div className="CardPreview faux"></div>
				<div className="CardPreview faux"></div>
			</div>
			<div className="definitions">
				<StateButton className="name" onClick={!this.props.placeholder ? this.onEditKey.bind(this) : null} loading={this.state.waitingEditKey}>
					<span className="value">{ name }</span>
					{ !this.props.placeholder && <span className="icon icon-edit" title={ t("rename") }></span> }
				</StateButton>
				{ !this.props.placeholder &&
					<div className="lastModified">
						{ this.props.last_modified
							? moment.utc(this.props.last_modified, Ruby_Date_Format).fromNow()
							: t("justNow").toLowerCase()
						}
					</div>
				}
			</div>
		</div>
	}
}

const mapStateToProps = state => ({
	sessionToken: state.session.token
})

const mapDispatchToProps = { updateSessionStackKey, bumpSessionStackToFirst }

export default connect(mapStateToProps, mapDispatchToProps)(StackPreview)