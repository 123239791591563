import React, { Component } from "react"
import ReactDOM from "react-dom"
import PropTypes from "prop-types"
import "./DraggableList.scss"
import { createRef } from "react"
import { getScrollParent } from "../../util"
import DraggableListItem from "./DraggableListItem"

export default class DraggableList extends Component {
	static propTypes = {
		className: PropTypes.string,
		onReorderItem: PropTypes.func,
		onDeleteItem: PropTypes.func,
		onDuplicateItem: PropTypes.func,
		dragAnywhere: PropTypes.bool,
		threshold: PropTypes.number,
		newlyCreatedIndex: PropTypes.number,
		onItemContextMenu: PropTypes.func
	}

	state = {
		draggedIndex: -1,
		targetIndex: -1,
		dragYPosition: 0
	}

	constructor (props) {
		super(props)
		this.wrapperRef = React.createRef()
		this.onMouseMove = this.onMouseMove.bind(this)
		this.onMouseUp = this.onMouseUp.bind(this)
		this.initialDragYPosition = null
		this.childrenRef = []
		this.childrenHeights = []
		this.reorderCount = 0
		this.scrollParent = null
		this.lastMouseY = 0
	}

	getScrollTop () {
		return this.scrollParent ? this.scrollParent.scrollTop : 0
	}

	onChildDragStart (e, index) {
		e.preventDefault()
		this.setState({
			draggedIndex: index,
			targetIndex: index
		})
		this.scrollParent = getScrollParent(this.wrapperRef.current)
		this.rectTop = this.wrapperRef.current.getBoundingClientRect().top
		this.initialScrollTop = this.getScrollTop()
		document.addEventListener("mousemove", this.onMouseMove)
		document.addEventListener("touchmove", this.onMouseMove, { passive: false })
		document.addEventListener("mouseup", this.onMouseUp)
		document.addEventListener("touchend", this.onMouseUp)
		requestAnimationFrame(() => this.scrollOnEdge())
		this.childrenHeights = this.childrenRef.map(childRef => {
			const node = ReactDOM.findDOMNode(childRef.current)
			return node.getBoundingClientRect().height // + parseInt(window.getComputedStyle(node).marginTop)
		})
	}

	onMouseMove (e) {
		e.preventDefault()
		let y
		if (e.touches) {
			y = e.touches[0].pageY
		} else {
			y = e.clientY
		}
		this.lastMouseY = y
		if (this.initialDragYPosition === null) {
			this.initialDragYPosition = y - this.rectTop
		}
		this.refreshMouseMove(this.lastMouseY)
	}

	refreshMouseMove (y) {
		const scrollDifference = this.getScrollTop() - this.initialScrollTop
		let dragYPosition = y - this.rectTop + scrollDifference
		this.setState({ dragYPosition })
		let targetIndex = this.getChildIndexAtY(dragYPosition)
		if (targetIndex !== null) {
			this.setState({ targetIndex })
		}
	}

	scrollOnEdge () {
		if (!this.scrollParent) {
			this.scrollParent = getScrollParent(this.wrapperRef.current)
			if (!this.scrollParent) {
				return
			}
		}
		const y = this.lastMouseY
		const scrollTop = this.scrollParent.scrollTop
		const topThreshold = 100
		const bottomThreshold = window.innerHeight - 100
		if (y < topThreshold) {
			this.scrollParent.scrollTo({ top: scrollTop - Math.round((topThreshold - y) / 10) })
		} else if (y > bottomThreshold) {
			this.scrollParent.scrollTo({ top: scrollTop + Math.round((y - bottomThreshold) / 10) })
		}
		this.refreshMouseMove(y)
		if (this.state.draggedIndex >= 0) {
			requestAnimationFrame(() => this.scrollOnEdge())
		}
	}

	onMouseUp () {
		document.removeEventListener("mousemove", this.onMouseMove)
		document.removeEventListener("touchmove", this.onMouseMove, { passive: false })
		document.removeEventListener("mouseup", this.onMouseUp)
		document.removeEventListener("touchend", this.onMouseUp)
		if (this.state.draggedIndex !== this.state.targetIndex) {
			this.props.onReorderItem(this.state.draggedIndex, this.state.targetIndex)
			this.reorderCount += 1
		}
		requestAnimationFrame(() => {
			this.initialDragYPosition = null
			this.setState({
				draggedIndex: -1,
				targetIndex: -1,
				dragYPosition: 0
			})
		})
	}

	isWithinRange (value, min, max) {
		return (value - min) * (value - max) <= 0
	}

	isDragging () {
		return this.initialDragYPosition !== null
	}

	getChildOffset (index) {
		if (!this.isDragging()) {
			return 0
		}
		if (this.state.draggedIndex === index) {
			return -(this.initialDragYPosition - this.state.dragYPosition)
		}
		if (this.isWithinRange(index, this.state.draggedIndex, this.state.targetIndex)) {
			return (index > this.state.draggedIndex ? -1 : 1) * this.childrenHeights[this.state.draggedIndex]
		}
		return 0
	}

	getChildHeight (index) {
		return this.childrenHeights[index]
	}

	getChildrenHeightRange (fromIndex, toIndex) {
		return this.childrenHeights
			.slice(fromIndex, toIndex)
			.reduce((r, height) => r + height)
	}

	getChildIndexAtY (y) {
		let accHeight = 0
		return this.childrenHeights.reduce((r, height, index) => {
			if (accHeight < y && y < accHeight + height) {
				r = index
			}
			accHeight += height
			return r
		}, null)
	}

	makeChildRef () {
		const ref = createRef()
		this.childrenRef.push(ref)
		return ref
	}

	render () {
		this.childrenRef = []
		return <div className={`DraggableList ${this.props.className} ${this.isDragging() && "dragging"}`} ref={this.wrapperRef}>
			{
				this.props.children.map((child, index) => <DraggableListItem
					key={`${this.reorderCount}-${index}`}
					itemTitle={this.props.itemTitle}
					offset={this.getChildOffset(index)}
					dragged={this.state.draggedIndex === index}
					onDragStart={e => this.onChildDragStart(e, index)}
					onDelete={this.props.onDeleteItem && (() => this.props.onDeleteItem(index))}
					onDuplicate={this.props.onDuplicateItem && (() => this.props.onDuplicateItem(index))}
					dragAnywhere={this.props.dragAnywhere}
					threshold={this.props.threshold}
					newlyCreated={this.props.newlyCreatedIndex === index}
					onContextMenu={this.props.onItemContextMenu ? e => this.props.onItemContextMenu(e, index) : undefined}
					ref={this.makeChildRef()}
				>
					{ child }
				</DraggableListItem>)
			}
		</div>
	}
}
