const DEFAULT_STATE = {
	home: null,
	cards: [],
	published: null,
	currentCard: null,
	fullyLoaded: false
}

const stack = (state = DEFAULT_STATE, action) => {
	switch (action.type) {
		case "STACK/SET":
			return {
				...state,
				...action.payload
			}
		case "STACK/CLEAR":
			return {
				...state,
				...DEFAULT_STATE
			}
		case "STACK/SET_PUBLISHED":
			return {
				...state,
				published: action.published
			}
		case "STACK/SET_HOME":
			return {
				...state,
				home: action.payload
			}
		case "STACK/SET_CARDS":
			return {
				...state,
				cards: action.payload
			}
		case "STACK/ADD_CARD":
			return {
				...state,
				cards: state.cards.concat([action.card])
			}
		case "STACK/SET_CURRENT_CARD":
			return {
				...state,
				currentCard: action.currentCard
			}
		case "STACK/FULLY_LOADED":
			return {
				...state,
				fullyLoaded: true
			}
		default:
			return state
	}
}

export default stack