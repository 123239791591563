import React from "react"
import EditableListField from "../EditableListField"
import { t } from "../../../intl"
import TextInput from "../../GUI/TextInput"
import ActionInput from "../../GUI/ActionInput"
import RevealableField from "../RevealableField"

export default function CallsToAction ({ onListChange, alwaysShow, callsToAction, guidingStarLocations }) {
	return <RevealableField label={t("add_object", { object: t("callsToAction") })} show={alwaysShow || (callsToAction && callsToAction.length > 0)}>
		<EditableListField
			label={t("callsToAction")}
			items={callsToAction || []}
			newItemTemplate={{ label: null, action: {} }}
			onChange={items => onListChange("callsToAction", items)}
			itemTitle={({ index }) => t("action_nx", { x: index + 1 })}
			component={CallToAction}
			guidingStarLocations={guidingStarLocations}
		/>
	</RevealableField>
}

const CallToAction = props => {
	const { index, label, action, onListChange } = props
	const guidingStarLocations = props.guidingStarLocations || []
	return <div className="field">
		<label>{ t("label") }</label>
		<TextInput
			value={label}
			onChangeFinal={label => onListChange(index, { label })}
			escCancelsEdit={true}
		/>
		<label>{ t("actionOnClick") }</label>
		<ActionInput
			{...action}
			onChange={action => onListChange(index, { action })}
			guidingStarLocations={guidingStarLocations}
		/>
	</div>
}