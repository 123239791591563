import React from 'react'
import TextInput from '../GUI/TextInput'
import CardInspector from './CardInspector'
import { t } from '../../intl'

export default class QRCodeCardInspector extends CardInspector {
	renderFields () {
		return <>
			<div className="field">
				<label>{ t("qr_link") }</label>
				<TextInput
					value={this.props.data.qrText}
					onChangeFinal={qrText => this.props.onDataChange({ qrText })}
					escCancelsEdit={true}
				/>
			</div>
			<div className="field">
				<label>{ t("description") }</label>
				<TextInput
					value={this.props.data.description}
					onChangeFinal={description => this.props.onDataChange({ description })}
					escCancelsEdit={true}
				/>
			</div>
		</>
	}

	static type = "qrCode"

	static getDefaultHash (cardId, cardName) {
		const props = super.getDefaultHash(cardId, cardName)
		props.data = {
			qrText: null
		}
		return props
	}

	static getAPISampleHash (cardName) {
		const props = super.getAPISampleHash(cardName)
		props.data = {
			title: "Stacky QR",
			description: "Scan to go to Stacky's homepage.",
			qrText: "https://stacky.app/"
		}
		return props
	}
}
