import React, { useEffect, useState, useRef } from "react"
import PropTypes from "prop-types"
import "./LoadingIndicator.scss"

function LoadingIndicator (props) {
	const { visible } = props
	const [ show, setShow ] = useState(true)
	const timeout = useRef()

	useEffect(() => {
		clearTimeout(timeout.current)
		timeout.current = setTimeout(() => {
			setShow(visible)
		}, visible ? 0 : 500)
		return () => {
			clearTimeout(timeout.current)
		}
	}, [visible, setShow])

	if (!show) {
		return null
	}
	
	return <div className={`LoadingIndicator ${visible && "visible"}`}>
		<div className="wheel" dangerouslySetInnerHTML={{__html: WHEEL_SVG}} />
		<div className="tick" dangerouslySetInnerHTML={{__html: TICK_SVG}} />
		<div className="tick" dangerouslySetInnerHTML={{__html: TICK_SVG}} />
		<div className="tick" dangerouslySetInnerHTML={{__html: TICK_SVG}} />
	</div>
}

LoadingIndicator.propTypes = {
	visible: PropTypes.bool
}

export default LoadingIndicator

const WHEEL_SVG = `<svg width="100%" height="100%" viewBox="0 0 188 188" version="1.1" style="fill-rule:evenodd;clip-rule:evenodd;stroke-linejoin:round;stroke-miterlimit:1.5;"><rect id="loading-wheel" x="0" y="0" width="187.5" height="187.5" style="fill:none;"/><clipPath id="_clip1"><rect x="0" y="0" width="187.5" height="187.5"/></clipPath><g clip-path="url(#_clip1)"><g><path d="M93.75,0l0,30"/><path d="M93.75,157.5l0,30"/><path d="M118.014,3.194l-7.764,28.978"/><path d="M77.25,155.328l-7.764,28.978"/><path d="M140.625,12.56l-15,25.981"/><path d="M61.875,148.959l-15,25.981"/><path d="M160.041,27.459l-21.213,21.213"/><path d="M48.672,138.828l-21.213,21.213"/><path d="M174.94,46.875l-25.981,15"/><path d="M38.541,125.625l-25.981,15"/><path d="M184.306,69.486l-28.978,7.764"/><path d="M32.172,110.25l-28.978,7.764"/><path d="M187.5,93.75l-30,0"/><path d="M30,93.75l-30,0"/><path d="M184.306,118.014l-28.978,-7.764"/><path d="M32.172,77.25l-28.978,-7.764"/><path d="M174.94,140.625l-25.981,-15"/><path d="M38.541,61.875l-25.981,-15"/><path d="M160.041,160.041l-21.213,-21.213"/><path d="M48.672,48.672l-21.213,-21.213"/><path d="M140.625,174.94l-15,-25.981"/><path d="M61.875,38.541l-15,-25.981"/><path d="M118.014,184.306l-7.764,-28.978"/><path d="M77.25,32.172l-7.764,-28.978"/></g></g></svg>`
const TICK_SVG = `<svg width="100%" height="100%" viewBox="0 0 188 188" version="1.1" style="fill-rule:evenodd;clip-rule:evenodd;stroke-linejoin:round;stroke-miterlimit:1.5;"><rect id="loading-tick" x="0" y="0" width="187.5" height="187.5" style="fill:none;"/><clipPath id="_clip1"><rect x="0" y="0" width="187.5" height="187.5"/></clipPath><g clip-path="url(#_clip1)"><path d="M93.75,0l0,30"/></g></svg>`