import React from "react"
import ClipboardInput from "../GUI/ClipboardInput"
import CardInspector from "./CardInspector"

export default class MusicAlbumCardInspector extends CardInspector {
	render () {
		return <div className="Inspector">
			<div className="field">
				<label>Apple Music share link</label>
				<ClipboardInput
					value={this.props.data.shareLink}
					onChange={shareLink => this.props.onDataChange({ shareLink })}
					pasteInstructions={"Please paste an Apple Music Album share link"}
				/>
			</div>
		</div>
	}

	static type = "musicAlbum"

	static getDefaultHash (cardId, cardName) {
		const props = super.getDefaultHash(cardId, cardName)
		props.data = {
			shareLink: ""
		}
		return props
	}

	static getAPISampleHash (cardName) {
		const props = super.getAPISampleHash(cardName)
		props.data = {
			shareLink: "https://music.apple.com/us/album/blue-night-bossa-ep/1364994652"
		}
		return props
	}
}
