import React, { Component } from "react"
import ReactDOM from "react-dom"
import PropTypes from "prop-types"
import arrayMove from "array-move"
import DraggableList from "./DraggableList"
import { t } from "../../intl"
import { createRef } from "react"
import { getScrollParent } from "../../util"
import GuidingStar, { STAR_ADD_NEW_ITEM } from "./GuidingStar"

export default class EditableList extends Component {
	static propTypes = {
		items: PropTypes.arrayOf(PropTypes.object).isRequired,
		onChange: PropTypes.func.isRequired,
		newItemTemplate: PropTypes.object.isRequired,
		component: PropTypes.func.isRequired,
		itemTitle: PropTypes.func.isRequired,
		newlyCreatedIndex: PropTypes.number,
		guidingStarLocations: PropTypes.arrayOf(PropTypes.string),
		onItemAdded: PropTypes.func.isRequired
	}

	state = {
		newlyCreatedIndex: -1
	}

	constructor (props) {
		super(props)
		this.draggableListRef = createRef()
		this.newlyCreatedTimeout = null
	}

	componentDidUpdate (prevProps) {
		if (prevProps.newlyCreatedIndex !== this.props.newlyCreatedIndex && this.props.newlyCreatedIndex >= 0) {
			const listNode = ReactDOM.findDOMNode(this.draggableListRef.current)
			const newNode = listNode.children[this.props.newlyCreatedIndex]
			const scrollParentNode = getScrollParent(listNode)
			if (scrollParentNode) {
				const parentPositionInScrollTop = scrollParentNode.scrollTop + listNode.getBoundingClientRect().top
				const targetTop = parentPositionInScrollTop + newNode.offsetTop - 260
				const visibleRange = [
					scrollParentNode.scrollTop,
					scrollParentNode.scrollTop + scrollParentNode.getBoundingClientRect().height
				]
				if (targetTop < visibleRange[0] || targetTop > visibleRange[1] - 260) {
					scrollParentNode.scrollTo({ top: targetTop })
				}
			}
		}
	}

	duplicateListItem (index) {
		const items = this.props.items.concat()
		const newItem = { ...this.props.items[index] }
		items.splice(index + 1, 0, newItem)
		this.props.onChange(items)
		requestAnimationFrame(() => this.props.onItemAdded(index + 1))
	}

	deleteListItem (index) {
		const items = this.props.items.concat()
		items.splice(index, 1)
		this.props.onChange(items)
	}

	addListItem (item) {
		this.props.onChange(this.props.items.concat([item]))
		requestAnimationFrame(() => this.props.onItemAdded(this.props.items.length - 1))
	}

	onListChange (index, change) {
		const items = this.props.items.concat()
		items[index] = {
			...items[index],
			...change
		}
		this.props.onChange(items)
	}
	
	onListReorder (from, to) {
		this.props.onChange(arrayMove(this.props.items, from, to))
	}

	render () {
		const guidingStarLocations = this.props.guidingStarLocations || []
		return <>
			<DraggableList onReorderItem={(from, to) => this.onListReorder(from, to)} onDuplicateItem={index => this.duplicateListItem(index)} onDeleteItem={index => this.deleteListItem(index)} newlyCreatedIndex={this.props.newlyCreatedIndex >= 0 ? this.props.newlyCreatedIndex : this.state.newlyCreatedIndex} ref={this.draggableListRef}>
				{ this.props.items.map((item, i) => {
					const Component = this.props.component
					return <Component
						{...item}
						index={i}
						key={i}
						itemTitle={this.props.itemTitle({...item, index: i})}
						onListChange={this.onListChange.bind(this)}
						guidingStarLocations={guidingStarLocations}
					/>
				}) }
			</DraggableList>
			<InspectorListNewItem onClick={() => this.addListItem(this.props.newItemTemplate)} newItemCaption={this.props.newItemCaption} guidingStar={guidingStarLocations.includes(STAR_ADD_NEW_ITEM)} />
		</>
	}
}

function InspectorListNewItem ({ newItemCaption, onClick, guidingStar }) {
	return <div className="InspectorListNewItem" onClick={onClick}>
		{ guidingStar && <GuidingStar /> }
		<div className="title">{ newItemCaption || t("addNewItem") }</div>
	</div>
}
