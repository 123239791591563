import React, { Component } from "react"
import PropTypes from "prop-types"
import { connect } from "react-redux"
import { setStack, addSessionStack } from "../actions"
import { axios_instance } from "../util/server"
import { t } from "../intl"
import "./StackCreate.scss"
import { rand } from "../util/math"
import Toolbar from "./GUI/Toolbar"
import CardPreview from "./GUI/CardPreview"
import StackSlideshowPreview from "./StackSlideshowPreview"
import { inspectorsRegistry } from "./Inspector/cardInspectors"
import GuidingStar, { TEMPLATE_RESTAURANT_MENU, TEMPLATE_PRODUCT_CATALOG, TEMPLATE_SIMPLE_RESTAURANT, TEMPLATE_LANDING_WITH_PRICING_CONTACT, TEMPLATE_LANDING_WITH_CATEGORIES_CONTACT } from "./GUI/GuidingStar"

const RANDOM_NAME_NOUNS = [
	"bunny",
	"duck",
	"turtle",
	"pigeon",
	"canary",
	"sunflower",
	"hawk",
	"toast",
	"owl",
	"sparrow",
	"seagull",
	"parrot",
	"swan"
]

const RANDOM_NAME_ADJECTIVES = [
	"happy",
	"cheerful",
	"suspicious",
	"intrepid",
	"intelligent",
	"diligent",
	"elated",
	"gleeful",
	"euphoric",
	"joyous",
	"shady",
	"skeptical",
	"wary",
	"cynical",
	"dodgy",
	"swift",
	"rapid",
	"hasty",
	"alacritous",
	"fascinating",
	"riveting",
	"intriguing"
]

const MAGIC_TAB = "magic"
const HOME_TAB = "home"

const TABS_ITEMS = {
	magic: [
		{
			name: TEMPLATE_LANDING_WITH_CATEGORIES_CONTACT,
			types: ["landing", "contact"],
			cardTitles: ["my_business", "contact"]
		},
		// {
		// 	name: TEMPLATE_LANDING_WITH_PRICING_CONTACT,
		// 	types: ["details", "pricingList"],
		// 	cardTitles: ["my_business", "pricingList"]
		// },
		{
			name: TEMPLATE_RESTAURANT_MENU,
			types: ["cardList", "pricingList"],
			cardTitles: ["the_menu", "drinks"]
		},
		{
			name: TEMPLATE_SIMPLE_RESTAURANT,
			types: ["pricingList"],
			cardTitles: ["drinks_menu"]
		},
		{
			name: TEMPLATE_PRODUCT_CATALOG,
			types: ["cardList", "details"],
			cardTitles: ["catalog", "product"]
		}
	],
	home: [
		{
			name: "landing",
			types: ["landing"],
			cardTitles: ["landing"]
		},
		{
			name: "details",
			types: ["details"],
			cardTitles: ["details"]
		},
		{
			name: "cardList",
			types: ["cardList"],
			cardTitles: ["cardList"]
		},
	]
}

class StackCreate extends Component {
	static propTypes = {
		sessionToken: PropTypes.string,
		onCloseModalDialog: PropTypes.func.isRequired,
		onStackOpen: PropTypes.func
	}

	state = {
		delayRenderStacks: true,
		closing: false,
		waitingServer: false,
		tab: MAGIC_TAB
	}

	componentDidMount () {
		this.delayStacksTimeout = setTimeout(() => this.setState({ delayRenderStacks: false }), 1400)
	}

	componentWillUnmount () {
		clearTimeout(this.delayStacksTimeout)
	}

	generateStackName () {
		const noun = RANDOM_NAME_NOUNS[rand(0, RANDOM_NAME_NOUNS.length - 1)]
		const adjective = RANDOM_NAME_ADJECTIVES[rand(0, RANDOM_NAME_ADJECTIVES.length - 1)]
		return `${adjective}_${noun}`
	}
	
	close () {
		this.setState({ closing: true })
		setTimeout(() => {
			this.props.onCloseModalDialog()
		}, 400)
	}

	createStack (cards, template = undefined) {
		this.setState({ waitingServer: true })
		const stackKey = this.generateStackName()
		axios_instance.post("/stacks/create", {
			session_token: this.props.sessionToken,
			stack_key: stackKey,
			template: template,
			cards,
			home: cards[0].id
		})
			.then(response => {
				if (response.data.stack) {
					const stack = response.data.stack
					this.props.addSessionStack(stack)
					this.props.setStack({
						...stack,
						currentCard: stack.home,
						fullyLoaded: true
					})
					this.props.onStackOpen(stack.key, stack.cards[0])
					this.close()
				}
			})
			.catch(console.error)
			.finally(() => this.setState({ waitingServer: false }))
	}

	onCreateMagicStack (name) {
		switch (name) {
			case TEMPLATE_RESTAURANT_MENU:
				this.createStack([inspectorsRegistry.cardList.getDefaultHash(undefined, t("the_menu"))], TEMPLATE_RESTAURANT_MENU)
				break
			case TEMPLATE_SIMPLE_RESTAURANT:
				this.createStack([inspectorsRegistry.pricingList.getDefaultHash(undefined, t("the_menu"))], TEMPLATE_SIMPLE_RESTAURANT)
				break
			case TEMPLATE_PRODUCT_CATALOG:
				this.createStack([inspectorsRegistry.cardList.getDefaultHash(undefined, t("catalog"))], TEMPLATE_PRODUCT_CATALOG)
				break
			case TEMPLATE_LANDING_WITH_PRICING_CONTACT:
				this.createStack([inspectorsRegistry.details.getDefaultHash(undefined, t("my_business"))], TEMPLATE_LANDING_WITH_PRICING_CONTACT)
				break
			case TEMPLATE_LANDING_WITH_CATEGORIES_CONTACT:
				this.createStack([inspectorsRegistry.landing.getDefaultHash(undefined, t("my_business"))], TEMPLATE_LANDING_WITH_CATEGORIES_CONTACT)
				break
		}
	}

	onCreateHomeStack (type) {
		const homeCard = inspectorsRegistry[type]
		this.createStack([homeCard.getDefaultHash()])
	}

	selectTab (tab) {
		this.setState({ tab })
	}
	
	renderStack (key, name, description, cardTitles, previewTypes, onClick) {
		return <div className="stack-option" onClick={onClick} key={`${key}_${name}`}>
			<StackSlideshowPreview>
				{ previewTypes.map((previewType, i) => <CardPreview
					key={previewType}
					type={previewType}
					name={t(cardTitles[i])}
					showPlate={true}
				/> )}
			</StackSlideshowPreview>
			<div className="details">
				<div className="name">{ t(name) }</div>
				<div className="description">{ t(description) }</div>
			</div>
		</div>
	}

	renderStacks () {
		if (this.state.delayRenderStacks) {
			return
		}
		const items = TABS_ITEMS[this.state.tab]
		const createMethod = this.state.tab === MAGIC_TAB ? this.onCreateMagicStack.bind(this) : this.onCreateHomeStack.bind(this)
		const descSuffix = this.state.tab === HOME_TAB ? 'home_' : ''
		return items.map(item => this.renderStack(this.state.tab, item.name, descSuffix + item.name + '_desc', item.cardTitles, item.types, () => createMethod(item.name)))
	}

	render () {
		return <div className={`StackCreate ${this.state.waitingServer && 'busy'} ${this.state.closing && 'closing'}`}>
			<div className="container">
				<Toolbar>
					<div className="group">
						<button className="free-floating" onClick={() => this.close()}>{ t("close") }</button>
					</div>
				</Toolbar>
				<div className="tabs">
					<button className={`tab free-floating ${this.state.tab === MAGIC_TAB && "selected"}`} onClick={() => this.selectTab(MAGIC_TAB)}>
						<div className="icon icon-stack-magic"></div>
						<div className="label">{ t("magic_templates") }</div>
					</button>
					<button className={`tab free-floating ${this.state.tab === HOME_TAB && "selected"}`} onClick={() => this.selectTab(HOME_TAB)}>
						<div className="icon icon-stack-home"></div>
						<div className="label">{ t("pick_starting_card") }</div>
					</button>
				</div>
				{ this.state.tab === MAGIC_TAB && <div className="guiding-star-hint">
					<div className="container">
						<GuidingStar />
						<div className="label">{ t("guidingStarExplanation") }</div>
					</div>
				</div> }
				<div className="stacks">
					{ this.renderStacks() }
				</div>
			</div>
		</div>
	}
}

const mapStateToProps = state => ({
	sessionToken: state.session.token,
	account: state.account
})

const mapDispatchToProps = { setStack, addSessionStack }

export default connect(mapStateToProps, mapDispatchToProps)(StackCreate)