export const setAccount = payload => ({
	type: 'ACCOUNT/SET',
	payload
})

export const setSessionToken = payload => ({
	type: 'SESSION/SET_TOKEN',
	payload
})

export const setSessionStacks = payload => ({
	type: 'SESSION/SET_STACKS',
	payload
})

export const addSessionStack = stack => ({
	type: 'SESSION/ADD_STACK',
	stack
})

export const updateSessionStackKey = (stackKey, newKey) => ({
	type: 'SESSION/UPDATE_STACK_KEY',
	stackKey,
	newKey
})

export const updateSessionStackHome = (stackKey, homeCard) => ({
	type: 'SESSION/UPDATE_STACK_HOME',
	stackKey,
	homeCard
})

export const bumpSessionStackToFirst = (stackKey) => ({
	type: 'SESSION/BUMP_STACK_TO_FIRST',
	stackKey
})

export const setStack = payload => ({
	type: 'STACK/SET',
	payload
})

export const clearStack = payload => ({
	type: 'STACK/CLEAR',
	payload
})

export const setStackPublished = published => ({
	type: 'STACK/SET_PUBLISHED',
	published
})

export const setStackCards = payload => ({
	type: 'STACK/SET_CARDS',
	payload
})

export const addStackCard = card => ({
	type: 'STACK/ADD_CARD',
	card
})

export const setStackHome = payload => ({
	type: 'STACK/SET_HOME',
	payload
})

export const setCurrentCard = currentCard => ({
	type: 'STACK/SET_CURRENT_CARD',
	currentCard
})

export const setStackFullyLoaded = payload => ({
	type: 'STACK/FULLY_LOADED'
})

export const openPopup = (settings, component, props) => ({
	type: 'UI/OPEN_POPUP',
	settings,
	component,
	props
})

export const closePopup = () => ({
	type: 'UI/CLOSE_POPUP'
})

export const refreshUISize = () => ({
	type: 'UI/REFRESH_SIZE'
})
