import React, { Component, createRef } from "react"
import { last } from "lodash-es"
import './TextInput.scss'

/**
 * @prop {*} value
 * @prop {string} tooltip
 * @prop {string} type
 * @prop {string} placeholder
 * @prop {array} suggestions
 * @prop {boolean} autoFocus
 * @prop {callback} onChange
 * @prop {callback} onChangePreview
 * @prop {callback} onChangeFinal
 * @prop {callback} onEditStart
 * @prop {callback} onEditEnd
 * @prop {boolean} escCancelsEdit
 * @prop {boolean} disabled
 * @prop {boolean} locked
 * @prop {boolean} autoExpand
 * @prop {string} emptyValue
 */
export default class TextInput extends Component {
	state = {
		editing: false,
		suggestionsPointer: -1
	}
	
	constructor (props) {
		super(props)
		this.endOnBlur = false
		this.inputRef = createRef()
	}

	isReadOnly () {
		return this.props.locked || this.props.disabled
	}

	onKeyDown (e) {
		switch (e.key) {
			case 'Escape':
				if (this.props.escCancelsEdit) {
					this.revertInputValue()
					this.finishEdit()
					break
				}
			// eslint-disable-next-line
			case 'Enter':
				e.target.blur()
				e.preventDefault()
				e.stopPropagation()
				break
			case 'ArrowUp':
			case 'ArrowDown':
				if (this.usingSuggestions()) {
					e.preventDefault()
					e.stopPropagation()
					this.moveSuggestionsPointer(e.key === 'ArrowDown' ? 1 : -1)
				}
				break
			default:
		}
	}

	moveSuggestionsPointer (mod) {
		let nextPointer = this.state.suggestionsPointer + mod
		if (this.getSuggestions()[nextPointer] === TextInput.SUGGESTIONS_SEPARATOR) {
			nextPointer += mod / Math.abs(mod)
		}
		nextPointer = wrapAround(
			nextPointer,
			0,
			this.getSuggestions().length
		)
		this.setState({
			suggestionsPointer: nextPointer
		})
		const nextValue = d(this.getSuggestions()[nextPointer])
		this.setCurrentValue(nextValue)
	}

	revertInputValue () {
		this.inputRef.current.value = this.initialValue
	}

	onEditStart () {
		this.endOnBlur = true
		this.newValue = this.getValue()
		this.initialValue = this.newValue
		this.setState({
			editing: true,
			suggestionsPointer: -1
		})
		if (this.props.onEditStart) {
			this.props.onEditStart()
		}
	}

	onEditStartAndSelect (e) {
		e.preventDefault()
		this.onEditStart()
		this.inputRef.current.select()
	}

	onFocus () {
		this.onEditStart()
	}

	onBlur () {
		if (this.endOnBlur) {
			this.submitChanges()
			this.finishEdit()
		}
	}

	finishEdit () {
		this.endOnBlur = false
		this.newValue = null
		this.setState({ editing: false })
		this.inputRef.current.blur()
		if (this.props.onEditEnd) {
			this.props.onEditEnd()
		}
	}

	onTextboxChange () {
		this.newValue = this.inputRef.current.value
		this.submitChanges(false)
	}

	setCurrentValue (value) {
		this.newValue = value
		this.inputRef.current.value = value
	}

	onClearValue () {
		this.newValue = this.getEmptyValue()
		this.inputRef.current.value = this.getEmptyValue()
		this.submitChanges()
	}

	getEmptyValue () {
		return this.props.emptyValue || ""
	}

	submitChanges (final = true) {
		if (!final && this.newValue !== this.getValue() && this.props.onChangePreview) {
			this.props.onChangePreview(this.newValue)
		}
		if (final && this.initialValue !== this.newValue && this.props.onChangeFinal) {
			this.props.onChangeFinal(this.newValue)
		}
		if (this.props.onChange) this.props.onChange(this.newValue)
	}

	getValue () {
		return Array.isArray(this.props.value) ? '' : this.props.value
	}

	componentDidUpdate () {
		const shouldInputRefBeUpdated = this.inputRef.current && this.props.value && this.props.value !== this.inputRef.current.value
		if (!this.state.editing && shouldInputRefBeUpdated) {
			this.inputRef.current.value = this.props.value
		}
	}

	useSuggestion (suggestion) {
		this.setCurrentValue(suggestion)
		this.submitChanges()
	}

	getSuggestions () {
		let suggestions = (this.props.suggestions || [])
			.filter(suggestion => suggestion && suggestion.length > 0)
		if (last(suggestions) === TextInput.SUGGESTIONS_SEPARATOR) {
			suggestions.pop()
		}
		return suggestions
	}

	usingSuggestions () {
		return this.getSuggestions().length > 0
	}

	render () {
		if (this.props.invisible) {
			return null
		}

		let value, placeholder, title
		let multipleValues = false
		value = this.getValue()

		if (Array.isArray(this.props.value)) {
			multipleValues = true
			placeholder = this.props.value.join(', ')
			title = d('multiple-values') + ': ' + placeholder
		} else {
			placeholder = this.props.placeholder
			title = this.props.tooltip
		}

		return <div className={`TextInput preset-${this.props.type} ${this.isReadOnly() ? 'disabled' : ''} ${this.state.editing && 'editing'} ${this.props.autoExpand && 'auto-expand'}`}>
			{ this.props.caption &&
				<label className="caption" onMouseUp={e => this.onEditStartAndSelect(e)}>
					{ this.props.caption }
				</label>
			}
			<div className="value">
				<input
					type={this.props.type || 'text'}
					id={this.props.caption}
					defaultValue={value}
					onKeyDown={e => this.onKeyDown(e)}
					onChange={() => this.onTextboxChange()}
					onFocus={() => this.onFocus()}
					onBlur={() => this.onBlur()}
					placeholder={placeholder}
					autoFocus={this.props.autoFocus}
					title={title}
					disabled={this.isReadOnly()}
					autoComplete={this.usingSuggestions() ? 'off' : ''}
					ref={this.inputRef}
				/>
				{ multipleValues && !this.isReadOnly() &&
					<div className="action clear" title={d('clear')} onClick={() => this.onClearValue()}>
						<span className="icon-close-light"></span>
					</div>
				}
				{ this.props.locked &&
					<div className="action edit" title={d('edit')} onClick={() => this.props.onLockClicked && this.props.onLockClicked()}>
						<span className="icon-edit"></span>
					</div>
				}
			</div>
			{ this.usingSuggestions() && this.state.editing &&
				<div className="suggestions" onMouseOver={() => this.setState({ suggestionsPointer: -1 })}>
					{
						this.getSuggestions().map((suggestion, i) =>
							suggestion === TextInput.SUGGESTIONS_SEPARATOR
								? <div key={`separator-${i}`} className="separator"></div>
								: <div key={suggestion}
									className={`item ${this.state.suggestionsPointer === i && 'selected'} ${suggestion === value && 'checked'}`}
									onMouseDown={() => this.useSuggestion(suggestion)}>
									{suggestion}
								</div>
						)
					}
				</div>
			}
		</div>
	}
}

function d (string) {
	return string
}

function wrapAround (value, min, max) {
	return value < min
		? max - (min - value) % (max - min)
		: min + (value - min) % (max - min)
}

TextInput.SUGGESTIONS_SEPARATOR = '---'
 