import React, { Component } from "react"
import './ClipboardInput.scss'
import PropTypes from "prop-types"
import SmartText from "../../util/smartText"

class ClipboardInput extends Component {
	// https://embed.music.apple.com/us/album/%E5%96%AE%E8%BA%AB%E6%A5%B5%E5%93%81/875198460
	// https://music.apple.com/us/album/%E5%96%AE%E8%BA%AB%E6%A5%B5%E5%93%81/875198460

	onClick () {
		let result = prompt(this.props.pasteInstructions, this.props.value)
		if (result) {
			this.props.onChange(result)
		}
	}

	validate (value) {
		return this.props.validator ? this.props.validator(value) : true
	}

	getLabel () {
		const value = new SmartText(this.props.value)
		if (value.isHttpLink()) {
			return value.getURL().host
		}
		return this.props.value
	}

	render () {
		const label = this.getLabel()
		const classNames = ['ClipboardInput']
		if (this.props.value) classNames.push('has-value')
		return <div className={classNames.join(' ')} onClick={this.onClick.bind(this)}>
			{ label && <div className="label">{ label }</div> }
		</div>
	}
}

ClipboardInput.propTypes = {
	pasteInstructions: PropTypes.string.isRequired,
	value: PropTypes.string,
	validator: PropTypes.func,
	onChange: PropTypes.func.isRequired
}

export default ClipboardInput