import React from "react"
import PropTypes from "prop-types"
import CardSelect from "../CardSelect"
import TextInput from "./TextInput"
import { t } from "../../intl"
import "./ActionInput.scss"
import AddressInput from "../Inspector/Bits/AddressInput"
import { useSelector } from "react-redux"

const linkTypes = [
	"none",
	"card",
	"external",
	"phone",
	"address",
	"email"
]

function ActionInput (props) {
	const devMode = useSelector(state => state.account.settings.dev_mode)
	
	const onDataChange = (data) => {
		const nextProps = {
			...props,
			...data
		}
		switch (nextProps.type) {
			case "card":
				props.onChange({
					type: nextProps.type,
					cardId: nextProps.cardId,
					params: nextProps.params
				})
				break
			case "external":
				props.onChange({
					type: nextProps.type,
					url: nextProps.url
				})
				break
			case "phone":
			case "email":
				props.onChange({
					type: nextProps.type,
					value: nextProps.value
				})
				break
			case "address":
				props.onChange({
					type: nextProps.type,
					street: nextProps.street,
					city: nextProps.city,
					state: nextProps.state,
					country: nextProps.country
				})
				break
			default:
			case "none":
				props.onChange({
					type: "none"
				})
				break
		}
	}
	
	const { street, city, state, country } = props
	const guidingStarLocations = props.guidingStarLocations || []
	return <div className="ActionInput columns">
		<div className="column">
			<select onChange={e => onDataChange({ type: e.target.value })} value={props.type}>
				{ linkTypes.map(type => {
					const starString = guidingStarLocations.includes(type) && props.type !== type ? "★ " : ""
					return <option key={type} value={type}>{ starString + t(`action_${type}`) }</option>
				}) }
			</select>
		</div>
		{ props.type === "card" && <div className="column wide">
			<CardSelect
				value={props.cardId}
				onChange={cardId => onDataChange({ cardId })}
				guidingStarLocations={guidingStarLocations}
			/>
			{ (devMode || props.params) && <>
				<label>{ t("queryParams") }</label>
				<TextInput
					value={props.params}
					onChangeFinal={params => onDataChange({ params })}
					escCancelsEdit={true}
				/>
			</>}
		</div> }
		{ props.type === "external" && <div className="column wide">
			<TextInput
				value={props.url}
				onChangeFinal={url => onDataChange({ url })}
				escCancelsEdit={true}
			/>
		</div> }
		{ props.type === "phone" && <div className="column wide">
			<TextInput
				value={props.value}
				onChangeFinal={value => onDataChange({ value })}
				escCancelsEdit={true}
			/>
		</div> }
		{ props.type === "email" && <div className="column wide">
			<TextInput
				value={props.value}
				onChangeFinal={value => onDataChange({ value })}
				escCancelsEdit={true}
			/>
		</div> }
		{ props.type === "address" && <div className="column wide">
			<AddressInput
				{...{street, city, state, country}}
				onChange={change => onDataChange(change)}
			/>
		</div> }
	</div>
}

ActionInput.propTypes = {
	type: PropTypes.string,
	cardId: PropTypes.string,
	params: PropTypes.string,
	url: PropTypes.string,
	devMode: PropTypes.bool
}

export default ActionInput