const DEFAULT_STATE = {
	username: null,
	settings: {
		dev_mode: false
	}
}

const account = (state = DEFAULT_STATE, action) => {
	switch (action.type) {
		case 'ACCOUNT/SET':
			return {
				...state,
				...action.payload
			}
		default:
			return state
	}
}

export default account