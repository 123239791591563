import React from "react"
import "./StateButton.scss"
import LoadingIndicator from "./LoadingIndicator"

export default function StateButton (props) {	
	const { className, children, loading, onClick, disabled } = props
	return <div className={`StateButton ${loading && "loading"}`} onTouchStart={() => {}}>
		<div className="center-overlay">
			<LoadingIndicator visible={loading} />
		</div>
		<div className="button">
			<button className={className} onClick={onClick} disabled={disabled}>{ children }</button>
		</div>
	</div>
}