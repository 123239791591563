import React from "react"
import { t } from "../../../intl"
import ImageInput from "../../GUI/ImageInput"

export default function BannerInput ({ onDataChange, banner }) {
	return <>
		<label>{ t("banner") }
			{ banner && <div className="field-action" onClick={() => onDataChange({ banner: null })}>{ t("remove") }</div> }
		</label>
		<div className="columns">
			<div className="column wide">
				<ImageInput
					value={banner}
					onChange={banner => onDataChange({ banner })}
				/>
			</div>
			<div className="column hint">
				{ t("banner_hint") }
			</div>
		</div>
	</>
}