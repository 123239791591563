import React from "react"
import CardInspector from "./CardInspector"
import { t } from "../../intl"
import CardSelect from "../CardSelect"
import EditableListField from "./EditableListField"
import LogoInput from "./Bits/LogoInput"
import RevealableField from "./RevealableField"
import RichTextInput from "../GUI/RichTextInput"
import ToggleInput from "../GUI/ToggleInput"
import CardLink from "./Bits/CardLink"

export default class LandingCardInspector extends CardInspector {
	renderFields () {
		const { guidingStarLocations } = this.props
		const { logo, bodyHTML } = this.props.data
		const contactCard = this.props.data.contactCard || {}
		return <>
			<RevealableField label={t("add_object", { object: t("branding_logotype") })} show={logo}>
				<div className="field">
					<LogoInput
						logo={logo}
						onDataChange={this.props.onDataChange}
					/>
				</div>
			</RevealableField>
			<RevealableField label={t("add_object", { object: t("body") })} show={bodyHTML}>
				<div className="field">
					<label>{ t("body") }</label>
					<RichTextInput
						value={bodyHTML}
						onChange={bodyHTML => this.props.onDataChange({ bodyHTML })}
						placeholder={ t("type_here") }
					/>
				</div>
			</RevealableField>
			<div className="field">
				<CardLink
					cardType="contact"
					cardId={contactCard.cardId}
					onChange={contactCard => this.props.onDataChange({ contactCard })}
					guidingStarLocations={guidingStarLocations.contactCard}
				/>
			</div>
			<EditableListField
				label={t("cards")}
				items={this.props.data.items}
				newItemTemplate={{ cardId: null }}
				onChange={items => this.onListChange("items", items)}
				itemTitle={({ index }) => t("card_nx", { x: index + 1 })}
				component={Item}
				guidingStarLocations={guidingStarLocations.cards}
			>
				<div className="field">
					<ToggleInput
						label={t("titles_only")}
						value={this.props.data.titlesOnly || false}
						onChange={titlesOnly => this.props.onDataChange({ titlesOnly })}
						loading={this.state.updatingSettings}
					/>
				</div>
			</EditableListField>
		</>
	}

	static type = "landing"

	static getDefaultHash (cardId, cardName) {
		const props = super.getDefaultHash(cardId, cardName)
		props.data = {
			items: [],
			contactCard: {}
		}
		return props
	}

	static getAPISampleHash (cardId, cardName) {
		const props = super.getAPISampleHash(cardId, cardName)
		props.data = {
			title: "My cards",
			body: t("thisIsTheBody"),
			contactCard: {
				cardId: "the-contact-card-id"
			},
			items: [
				{
					cardId: this.generateCardId()
				}
			]
		}
		return props
	}
}

const Item = props => {
	const { index, onListChange, cardId, guidingStarLocations } = props
	return <>
		<div className="field">
			<CardSelect
				value={cardId}
				onChange={cardId => onListChange(index, { cardId })}
				guidingStarLocations={guidingStarLocations}
			/>
		</div>
	</>
}