import React, { useState } from "react"
import { t } from "../../intl"
import EditableList from "../GUI/EditableList"
import { useRef } from "react"

export default function EditableListField ({ children, className, label, newItemCaption, items, component, itemTitle, onChange, newItemTemplate, guidingStarLocations }) {
	const [ newlyCreatedIndex, setNewlyCreatedIndex ] = useState(-1)
	const newlyCreatedTimeout = useRef(null)

	const animateNewlyCreated = (index) => {
		setNewlyCreatedIndex(index)
		clearTimeout(newlyCreatedTimeout.current)
		newlyCreatedTimeout.current = setTimeout(() => {
			setNewlyCreatedIndex(-1)
		}, 1000)
	}

	const onItemAdded = (index) => {
		setNewlyCreatedIndex(index)
	}

	const onAddListItem = (newItem) => {
		onChange(items.concat([newItem]))
		animateNewlyCreated(items.length)
	}

	return <div className={`field group ${className}`}>
		<div className="label">
			{ label }
			<button className="field-action free-floating" onClick={() => onAddListItem(newItemTemplate)}>{ newItemCaption || t("addNewItem") }</button>
		</div>
		{ children }
		<EditableList
			items={items || []}
			newItemTemplate={newItemTemplate}
			newItemCaption={newItemCaption}
			onChange={onChange}
			onItemAdded={onItemAdded}
			itemTitle={itemTitle}
			component={component}
			newlyCreatedIndex={newlyCreatedIndex}
			guidingStarLocations={guidingStarLocations}
		/>
	</div>
}