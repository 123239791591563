import React from "react"
import TextInput from "../GUI/TextInput"
import ImageInput from "../GUI/ImageInput"
import CardInspector from "./CardInspector"
import { t } from "../../intl"
import EditableListField from "./EditableListField"
import RevealableField from "./RevealableField"

export default class ImagesCardInspector extends CardInspector {
	renderFields () {
		return <>
			<RevealableField label={t("add_object", { object: t("shortDescription") })} show={this.props.data.description}>
				<div className="field">
					<label>{ t("shortDescription") }</label>
					<TextInput
						value={this.props.data.description}
						onChangeFinal={description => this.props.onDataChange({ description })}
						escCancelsEdit={true}
					/>
					<div className="hint">{ t("cardShortDescriptionHint") }</div>
				</div>
			</RevealableField>
			<EditableListField
				label={t("images")}
				items={this.props.data.images || []}
				newItemCaption={t("addNewImage")}
				newItemTemplate={{ url: null }}
				onChange={items => this.onListChange("images", items)}
				itemTitle={({ index }) => t("image_nx", { x: index + 1 })}
				component={Image}
			/>
		</>
	}

	static type = "images"

	static getDefaultHash (cardId, cardName) {
		const props = super.getDefaultHash(cardId, cardName)
		props.data = {
			images: []
		}
		return props
	}

	static getAPISampleHash (cardName) {
		const props = super.getAPISampleHash(cardName)
		props.data = {
			title: "My pictures",
			images: [
				{
					url: "http://mydomain.com/myimage.jpg"
				},
				{
					url: "http://mydomain.com/myimage.jpg"
				}
			]
		}
		return props
	}
}

const Image = props => {
	const { index, url, onListChange } = props
	return <div className="field">
		<ImageInput
			value={url}
			onChange={url => onListChange(index, { url }) }
		/>
	</div>
}
