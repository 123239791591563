import React, { Component } from "react"
import md5 from 'js-md5'
import { t } from "../../intl"
import TextInput from "../GUI/TextInput"
import "./Inspector.scss"
import { store } from "../.."

const dataSourceTypes = ["static", "staticWithUrlQuery", "urlQuery", "api"]

export default class CardInspector extends Component {
	state = {
		animateFields: true
	}
	
	onListChange (propertyName, items) {
		const dataChange = {}
		dataChange[propertyName] = items.concat()
		this.props.onDataChange(dataChange)
	}

	componentDidMount () {
		this.animateTimeout = setTimeout(() => {
			this.setState({ animateFields: false })
		}, 1500)
	}

	componentWillUnmount () {
		clearTimeout(this.animateTimeout)
	}

	renderFields () {
		return null
	}

	renderHomeAction () {
		return <div className="floating-field">
			{ this.props.homeCardId === this.props.id
				? <div className="title"><span className="icon icon-home"></span>{ t("thisIsHomeCard") }</div>
				: <button className="floaty" style={{ width: "100%" }} onClick={() => this.props.onMakeHomeCard(this.props.id)}><span className="icon icon-home"></span> { t("makeThisHomeCard") }</button>
			}
		</div>
	}

	render () {
		const dataSource = this.props.data.dataSource || "static"
		const shouldRenderDataSource = dataSource !== "static" || this.props.devMode
		const shouldRenderFields = dataSource.includes("static")
		const shouldRenderAPISettings = dataSource.includes("api")
		return <div className={`Inspector ${this.state.animateFields && "animate-fields"}`}>
			<div className="card-info">
				<div className="type">{ t(this.constructor.type) }</div>
				{ this.props.devMode && <div className="id">
					<div className="label">{ t("cardId") }</div>
					<code className="value">{ this.props.id }</code>
				</div> }
			</div>
			{ this.renderHomeAction() }
			{ shouldRenderDataSource &&
				<div className="field">
					<label>{ t("cardDataSource") }</label>
					<select onChange={e => this.props.onDataChange({ dataSource: e.target.value })} value={this.props.data.dataSource}>
						{ dataSourceTypes.map(type => <option key={type} value={type}>{ t(type) }</option>)}
					</select>
				</div>
			}
			<div className="field">
				<label>{ t("name") }</label>
				<TextInput
					value={this.props.name}
					onChangeFinal={name => this.props.onNameChange(name)}
					escCancelsEdit={true}
				/>
			</div>
			{ shouldRenderFields && this.renderFields() }
			{ shouldRenderAPISettings && <>
				<div className="field">
					<label>{ t("endpointURL") }</label>
					<TextInput
						value={this.props.data.apiURL}
						onChangeFinal={apiURL => this.props.onDataChange({ apiURL })}
						escCancelsEdit={true}
					/>
				</div>
				<div className="field">
					<label>{ t("sample_structure") }</label>
					<div className="hint">{ t("sample_structure_hint") }</div>
					<pre dangerouslySetInnerHTML={{ __html: JSON.stringify(this.constructor.getAPISampleHash(), null, "\t") }} />
				</div>
				{/* <div className="field">
					<label>Endpoint URL</label>
					<select onChange={e => this.props.onDataChange({ apiRequestType: e.target.value })} value={this.props.data.apiRequestType}>
						<option value="get">GET</option>
						<option value="post">POST</option>
					</select>
				</div> */}
			</>}
			{/* { this.props.devMode && <div className="field">
					<label>Data</label>
					<pre dangerouslySetInnerHTML={{ __html: JSON.stringify(this.props.data, null, "\t") }} />
				</div>
			} */}
			<div className="bottom-spacing"></div>
		</div>
	}

	static type = null

	static generateCardId () {
		return md5(`${+ new Date()}`)
	}

	static getDefaultName (index = null) {
		if (index === null) index = store.getState().stack.cards.length + 1
		return t("item_nx", { item: t(this.type), x: index })
	}

	static getDefaultHash (cardId = null, cardName = null) {
		if (!cardId) cardId = this.generateCardId()
		if (!cardName) cardName = this.getDefaultName()
		return {
			id: cardId,
			name: cardName,
			type: this.type,
			data: {}
		}
	}

	static getSampleHash (cardId = null, cardName = null) {
		if (!cardId) cardId = this.generateCardId()
		if (!cardName) cardName = this.getDefaultName()
		return {
			id: cardId,
			name: cardName,
			type: this.type,
			data: {}
		}
	}

	static getPlateHash (cardId = null, cardName = null) {
		if (!cardId) cardId = this.generateCardId()
		if (!cardName) cardName = this.getDefaultName()
		return {
			id: cardId,
			name: cardName,
			type: this.type,
			data: {},
			showPlate: true
		}
	}

	static getAPISampleHash (cardName = null) {
		if (!cardName) cardName = this.getDefaultName()
		return {
			name: cardName,
			data: {}
		}
	}
}