import React from "react"
import PropTypes from "prop-types"
import CardPreview from "./CardPreview"
import "./CardList.scss"
import { useEffect } from "react"
import { useRef } from "react"
import { useState } from "react"
import DraggableList from "./DraggableList"
import SmartText from "../../util/smartText"
import GuidingStar from "./GuidingStar"
import { useDispatch, useSelector } from "react-redux"
import { closePopup, openPopup } from "../../actions"
import { t } from "../../intl"
import ContextualMenu from "./ContextualMenu"

function CardList ({ view, animateIn, cards, value, onChange, editable, onListReorder, onDeleteItem, onDuplicateItem, fullyClickable, cardTypes }) {
	const wrapper = useRef()
	const selectedRef = useRef()
	const [ animatingIn, setAnimatingIn ] = useState(animateIn)
	const uiSize = useSelector(state => state.ui.size)
	const homeCardId = useSelector(state => state.stack.home)
	const dispatch = useDispatch()
	const [ contextMenuIndex, setContextMenuIndex ] = useState(-1)
	const moreButton = uiSize === "large" && editable

	useEffect(() => {
		setTimeout(() => setAnimatingIn(false), 1000)
	}, [])
	
	useEffect(() => {
		if (selectedRef.current) {
			const visibleRange = [
				wrapper.current.scrollTop,
				(wrapper.current.scrollTop + wrapper.current.getBoundingClientRect().height) || window.innerHeight - 50
			]
			const selectedTop = selectedRef.current.parentNode.offsetTop
			if (selectedTop < visibleRange[0] || selectedTop > visibleRange[1]) {
				wrapper.current.scrollTo({ top: selectedTop - 60 })
			}
		}
	}, [value])

	const closeContextMenu = () => {
		setContextMenuIndex(-1)
		dispatch(closePopup())
	}
	
	const onItemContextMenu = (e, index) => {
		setContextMenuIndex(index)
		dispatch(openPopup(
			{
				style: {
					top: e.pageY,
					left: e.pageX,
					transformOrigin: `left top`
				},
				preset: "fast",
				onClose: () => closeContextMenu()
			},
			ContextualMenu,
			{
				options: [
					{
						label: t("duplicate"),
						onSelect: () => {
							onDuplicateItem(index)
							closeContextMenu()
						}
					},
					{
						label: t("delete"),
						onSelect: () => {
							onDeleteItem(index)
							closeContextMenu()
						}
					}
				]
			}
		))
	}

	const renderItems = () => {
		const filteredCards = cardTypes ? cards.filter(card => cardTypes.includes(card.type)) : cards
		return filteredCards.map((card, i) => {
			const description = card.description || card.data.description
			const selected = card.id === value
			const shortName = new SmartText(card.name || card.data.title).ellipsis(20)
			const homeIcon = card.id === homeCardId ? <span className="icon icon-home"></span> : null
			return <div className={`card-item ${selected && "selected"}`} key={`${i}-${selected}`} ref={selected ? selectedRef : undefined} onClick={fullyClickable ? () => onChange(card) : undefined}>
				{ card.guidingStar && <GuidingStar /> }
				<CardPreview
					{...card}
					onClick={() => onChange(card)}
				/>
				{ view === "thumbnails" && <>
					<div className="name">{ homeIcon }{ shortName }</div>
				</> }
				{ view === "list" && <div className="description">
					<div className="name">{ shortName }</div>
					{ description && <div className="info">{ description.substr(0, 280) }</div> }
				</div> }
				{ moreButton && <button className={`more ${contextMenuIndex === i && 'active'}`} onClick={e => onItemContextMenu(e, i)}>
					<div className="icon icon-more"></div>
				</button>}
			</div>
		})
	}
	
	return <div className={`CardList popup-component-support ${animatingIn && "animate-in"} ${view}`} ref={wrapper}>
		{ editable
			? <DraggableList
					dragAnywhere={true}
					threshold={5}
					onReorderItem={onListReorder}
					onDuplicateItem={onDuplicateItem}
					onDeleteItem={onDeleteItem}
					onItemContextMenu={moreButton ? onItemContextMenu : undefined}
				>
					{ renderItems() }
				</DraggableList>
			: renderItems()
		}
	</div>
}

CardList.propTypes = {
	cards: PropTypes.array.isRequired,
	value: PropTypes.string,
	onChange: PropTypes.func,
	onListReorder: PropTypes.func,
	onDuplicateItem: PropTypes.func,
	onDeleteItem: PropTypes.func,
	animateIn: PropTypes.bool,
	editable: PropTypes.bool,
	guidingStar: PropTypes.bool
}

export default CardList