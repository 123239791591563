import React from "react"
import PropTypes from "prop-types"
import "./ContextualMenu.scss"

function ContextualMenu ({ options }) {
	return <div className="ContextualMenu">
		{ options.map(option => {
			const { label, onSelect } = option
			return <button className="menu-item" onClick={onSelect} key={label}>
				<div className="label">{ label }</div>
			</button>
		})}
	</div>
}

ContextualMenu.propTypes = {
	options: PropTypes.array.isRequired
}

export default ContextualMenu