import axios from "axios"
import { t } from "../intl"

export const isProduction = process.env.NODE_ENV === "production"
const isStaging = window.location.host === "create-staging.stacky.app"

export const Production_Viewer_URL = "https://stacky.app"

export const Server_URL = isStaging
	? "https://stacky-staging.herokuapp.com"
	: (isProduction
		? "https://api.stacky.app"
		: "http://192.168.1.47:9292"
	)

export const Viewer_URL = isStaging
	? "https://staging.stacky.app"
	: (isProduction
		? Production_Viewer_URL
		: "http://192.168.1.47:3030"
	)

export const axios_instance = axios.create({
	baseURL: Server_URL
})

export const exposeServerError = e => {
	if (e.response && e.response.data) {
		alert(e.response.data)
	} else {
		t("generic_server_error")
		console.error(e)
	}
}