import React from "react"
import TextInput from "../GUI/TextInput"
import CardInspector from "./CardInspector"
import { t } from "../../intl"
import EditableListField from "./EditableListField"
import OptionsInput from "../GUI/OptionsInput"
import AddressInput from "./Bits/AddressInput"
import RevealableField from "./RevealableField"
import RichTextInput from "../GUI/RichTextInput"

export default class ContactCardInspector extends CardInspector {
	renderFields () {
		const { bodyHTML } = this.props.data
		return <>
			<RevealableField label={t("add_object", { object: t("body") })} show={bodyHTML}>
				<div className="field">
					<label>{ t("body") }</label>
					<RichTextInput
						value={bodyHTML}
						onChange={bodyHTML => this.props.onDataChange({ bodyHTML })}
						placeholder={ t("type_here") }
					/>
				</div>
			</RevealableField>
			<EditableListField
				label={t("fields")}
				items={this.props.data.fields || []}
				newItemCaption={t("addNewField")}
				newItemTemplate={{ type: null, value: null }}
				onChange={items => this.onListChange("fields", items)}
				itemTitle={({name, type}) => name || t(type || "field")}
				component={Field}
			/>
		</>
	}

	static type = "contact"

	static getDefaultHash (cardId, cardName) {
		const props = super.getDefaultHash(cardId, cardName)
		props.data = {
			fields: []
		}
		return props
	}

	static getSampleHash (cardId, cardName) {
		const props = super.getDefaultHash(cardId, cardName)
		props.data = {
			fields: [
				{
					type: "phone",
					value: "(415) 123-4567"
				},
				{
					type: "address",
					value: "123 Fake St, Springfield"
				},
				{
					type: "email",
					value: "john@doe.com"
				}
			]
		}
		return props
	}

	static getAPISampleHash (cardName) {
		const props = super.getAPISampleHash(cardName)
		props.data = {
			title: "My contact info",
			fields: [
				{
					type: "phone",
					value: "(415) 123-4567"
				},
				{
					type: "address",
					value: "123 Fake St, Springfield, California, USA"
				},
				{
					type: "address",
					street: "123 Fake St",
					city: "Springfield",
					state: "California",
					country: "USA",
				},
				{
					type: "email",
					value: "john@doe.com"
				}
			]
		}
		return props
	}
}

const FIELD_TYPES = ["phone", "address", "email", "website_profile", "messaging", "external_link"]
const DEFAULT_NAME_PRESET = {
	website_profile: "twitter",
	messaging: "telegram",
	external_link: null
}

const FieldLabel = ({ options, index, onListChange, type, namePreset, name, allowOther }) => {
	return <>
		<OptionsInput
			options={options}
			emptyLabel={allowOther ? t("other_label") + "..." : undefined}
			value={namePreset}
			onChange={namePreset => onListChange(index, { namePreset, name: null })}
		/>
		{ !namePreset && <TextInput
			value={name}
			placeholder={t(type)}
			onChangeFinal={name => onListChange(index, { name })}
			escCancelsEdit={true}
		/> }
	</>
}

const Field = ({ index, onListChange, type, namePreset, name, value, street, city, state, country }) => {
	return <div className="field">
		{ !type && <div className="actions-list wide">
			{ FIELD_TYPES.map(type => <button key={type} className="floaty" onClick={() => onListChange(index, { type, name: null, namePreset: DEFAULT_NAME_PRESET[type] === undefined ? type : DEFAULT_NAME_PRESET[type] })}>{ t(type) }</button>) }
		</div> }
		{ type === "phone" && <div className="columns">
			<div className="column wide">
				<FieldLabel
					allowOther={true}
					options={["phone", "main_phone", "second_phone"]}
					{...{index, onListChange, type, namePreset, name}}
				/>
			</div>
			<div className="column wide">
				<TextInput
					value={value}
					placeholder={t("phoneNumber")}
					onChangeFinal={value => onListChange(index, { value })}
					escCancelsEdit={true}
				/>
			</div>
		</div> }
		{ type === "email" && <div className="columns">
			<div className="column wide">
				<FieldLabel
					allowOther={true}
					options={["email"]}
					{...{index, onListChange, type, namePreset, name}}
				/>
			</div>
			<div className="column wide">
				<TextInput
					value={value}
					placeholder={t("emailAddress")}
					onChangeFinal={value => onListChange(index, { value })}
					escCancelsEdit={true}
				/>
			</div>
		</div> }
		{ type === "address" && <div className="columns">
			<div className="column wide">
				<FieldLabel
					allowOther={true}
					options={["address"]}
					{...{index, onListChange, type, namePreset, name}}
				/>
			</div>
			<div className="column wide">
				<AddressInput
					{...{street, city, state, country}}
					onChange={change => onListChange(index, change)}
				/>
			</div>
		</div> }
		{ type === "website_profile" && <div className="columns">
			<div className="column wide">
				<FieldLabel
					allowOther={false}
					options={["twitter", "instagram", "facebook", "youtube"]}
					{...{index, onListChange, type, namePreset, name}}
				/>
			</div>
			<div className="column wide">
				<TextInput
					value={value}
					placeholder={t("account_name")}
					onChangeFinal={value => onListChange(index, { value })}
					escCancelsEdit={true}
				/>
			</div>
		</div> }
		{ type === "messaging" && <div className="columns">
			<div className="column wide">
				<FieldLabel
					allowOther={false}
					options={["telegram", "whatsapp"]}
					{...{index, onListChange, type, namePreset, name}}
				/>
			</div>
			<div className="column wide">
				<TextInput
					value={value}
					placeholder={t("account_name")}
					onChangeFinal={value => onListChange(index, { value })}
					escCancelsEdit={true}
				/>
				<div className="hint">{ t(`${namePreset}_instructions`) }</div>
			</div>
		</div> }
		{ type === "external_link" && <div className="columns">
			<div className="column wide">
				<TextInput
					value={name}
					placeholder={t(type)}
					onChangeFinal={name => onListChange(index, { name })}
					escCancelsEdit={true}
				/>
			</div>
			<div className="column wide">
				<TextInput
					value={value}
					placeholder="http://"
					onChangeFinal={value => onListChange(index, { value })}
					escCancelsEdit={true}
				/>
			</div>
		</div> }
	</div>
}
