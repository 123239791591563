import React from "react"
import PropTypes from "prop-types"
import LoadingIndicator from "./LoadingIndicator"
import "./ToggleInput.scss"

function ToggleInput ({ label, value, onChange, loading }) {
	return <div className={`ToggleInput ${loading && "loading"}`} onClick={() => onChange(!value)}>
		<div className="center-overlay">
			<LoadingIndicator visible={true} />
		</div>
		<div className="input">
			<input type="checkbox" checked={value} readOnly />
			<label>{ label }</label>
		</div>
	</div>
}

ToggleInput.propTypes = {
	label: PropTypes.string,
	value: PropTypes.bool,
	loading: PropTypes.bool,
	onChange: PropTypes.func.isRequired,
}

export default  ToggleInput