import React, { Component, createRef } from "react"
import { connect } from "react-redux"
import PropTypes from "prop-types"
import { axios_instance, exposeServerError } from "../../util/server"
import { setAccount, setSessionToken } from "../../actions"
import { t } from "../../intl"
import "./PageView.scss"
import { CSSTransition } from "react-transition-group"
import LoadingIndicator from "../GUI/LoadingIndicator"
import { openSupportStack } from "../../util"

class LoginView extends Component {
	static propTypes = {
		setAccount: PropTypes.func,
		setSessionToken: PropTypes.func
	}

	state = {
		requestedLoginToken: false,
		waitingLoginTokenInput: false,
		confirmingLoginTokenInput: false,
		lightPositionRate: { x: 0.5, y: 0.5 },
		sceneTransitioning: false,
		waitingForServer: false
	}

	constructor (props) {
		super(props)
		this.sceneTransitioningTimeout = null
		this.emailInputRef = createRef()
		this.codeInputRef = createRef()
		this.onWindowMouseMove = this.onWindowMouseMove.bind(this)
	}

	componentDidMount () {
		window.addEventListener("mousemove", this.onWindowMouseMove)
	}

	componentWillUnmount () {
		window.removeEventListener("mousemove", this.onWindowMouseMove)
	}

	startTransitioning () {
		clearTimeout(this.sceneTransitioningTimeout)
		this.setState({ sceneTransitioning: true })
		this.sceneTransitioningTimeout = setTimeout(() => this.setState({ sceneTransitioning: false }), 550)
	}

	async onRequestLoginCode (e) {
		e.preventDefault()
		const username = this.emailInputRef.current.value
		if (username.length < 7) {
			return
		}
		this.username = username
		this.setState({
			requestedLoginToken: true,
			waitingForServer: true
		})
		this.startTransitioning()
		try {
			const response = await axios_instance.get(`/login_request?username=${this.username}`)
			if (response.data && response.data.result === "success") {
				this.setState({
					waitingLoginTokenInput: true,
					waitingForServer: false
				})
			}
		} catch (e) {
			this.setState({
				requestedLoginToken: false,
				waitingForServer: false
			})
			exposeServerError(e)
		}
	}

	async submitLoginToken (token) {
		this.setState({
			confirmingLoginTokenInput: true,
			waitingForServer: true
		})
		this.startTransitioning()
		try {
			const response = await axios_instance.post("/login", {
				username: this.username,
				login_token: token
			})
			if (response.data) {
				const { account, session } = response.data
				const sessionToken = session.token
				localStorage.setItem("sessionToken", sessionToken)
				this.props.setSessionToken(sessionToken)
				localStorage.setItem("account", JSON.stringify(account))
				this.props.setAccount(account)
			}
		} catch (e) {
			this.setState({
				confirmingLoginTokenInput: false,
				waitingForServer: false
			})
			exposeServerError(e)
		}
	}

	onCodeInputChange (e) {
		const token = e.target.value
		if (token.length === 6) {
			this.submitLoginToken(token)
		}
	}

	onWindowMouseMove (e) {
		this.setState({
			lightPositionRate: {
				x: e.pageX / window.innerWidth - 0.5,
				y: e.pageY / window.innerHeight - 0.5
			}
		})
	}

	getSpotlightStyle () {
		const { x, y } = this.state.lightPositionRate
		return {
			left: `${50 + x * 20}%`,
			top: `${50 + y * 20}%`
		}
	}

	getCastShadowStyle (offsetY = 0.0) {
		const { x, y } = this.state.lightPositionRate
		const finalY = y + offsetY
		return {
			transform: `translate3d(${-x * 1.2}vw, ${-finalY * 1.2}vw, 0)`,
			opacity: Math.min(1, 1.1 - Math.abs(finalY))
		}
	}

	render () {
		return <div className={`PageView LoginView ${(this.state.sceneTransitioning || this.state.waitingForServer) && 'scene-transitioning'}`}>
			<div className="spotlight" style={this.getSpotlightStyle()}></div>
			<div className="acrylic-logo">
				<div className="cast-shadow" style={this.getCastShadowStyle(0.3)}></div>
				<div className="logo"></div>
			</div>
			<div className="dialog">
				<CSSTransition
					classNames="dialog-elements"
					timeout={500}
					mountOnEnter={true}
					unmountOnExit={true}
					in={!this.state.waitingLoginTokenInput && !this.state.requestedLoginToken && !this.state.sceneTransitioning}
				>
					<form onSubmit={this.onRequestLoginCode.bind(this)}>
						<div className="title">{ t("signUpOrSignIn") }</div>
						<div className="acrylic-input">
							<div className="cast-shadow" style={this.getCastShadowStyle()}></div>
							<input type="email" name="email" ref={this.emailInputRef} placeholder={ t('email') } />
						</div>
						<div className="acrylic-button">
							<div className="cast-shadow" style={this.getCastShadowStyle()}></div>
							<input type="submit" value={ t("requestLoginCode") } />
						</div>
					</form>
				</CSSTransition>
				<CSSTransition
					classNames="dialog-elements"
					timeout={500}
					mountOnEnter={true}
					unmountOnExit={true}
					in={this.state.waitingLoginTokenInput && !this.state.confirmingLoginTokenInput && !this.state.sceneTransitioning}
				>
					<div>
						<div className="title">{ t("insertCode") }</div>
						<div className="hint">{ t("insertCodeHint") }</div>
						<div className="acrylic-input digits-input">
							<div className="cast-shadow" style={this.getCastShadowStyle()}></div>
							<input type="text" name="code" onChange={this.onCodeInputChange.bind(this)} />
						</div>
					</div>
				</CSSTransition>
			</div>
			<CSSTransition
				classNames="dialog-elements"
				timeout={500}
				mountOnEnter={true}
				unmountOnExit={true}
				in={!this.state.sceneTransitioning && this.state.waitingForServer}
			>
				<div className="center-overlay">
					<LoadingIndicator visible={true} />
				</div>
			</CSSTransition>
			<div className="footer">
				<button className="free-floating" onClick={() => openSupportStack()}>{ t("support") }</button>
			</div>
		</div>
	}
}

const mapDispatchToProps = { setAccount, setSessionToken }

export default connect(null, mapDispatchToProps)(LoginView)