import React, { useEffect, useState } from "react"
import "./StackSlideshowPreview.scss"
import { useRef } from "react"

function StackSlideshowPreview ({ children }) {
	const [ index, setIndex ] = useState(0)
	const interval = useRef()
	const childrenCount = children.length

	useEffect(() => {
		interval.current = setInterval(() => {
			let newIndex = index + 1
			if (newIndex >= childrenCount) {
				newIndex = 0
			}
			setIndex(newIndex)
		}, 2500)
		return () => {
			clearInterval(interval.current)
		}
	}, [index, childrenCount])

	return <div className="StackPreview StackSlideshowPreview">
		<div className="preview" style={{ left: `${index * -100}%` }}>
			{ children }
		</div>
	</div>
}

export default StackSlideshowPreview