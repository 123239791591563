import React from "react"
import "./GuidingStar.scss"

export const TEMPLATE_RESTAURANT_MENU = "restaurant_menu"
export const TEMPLATE_SIMPLE_RESTAURANT = "simple_restaurant"
export const TEMPLATE_PRODUCT_CATALOG = "product_catalog"
export const TEMPLATE_LANDING_WITH_PRICING_CONTACT = "landing_pricing_contact"
export const TEMPLATE_LANDING_WITH_CATEGORIES_CONTACT = "landing_categories_contact"

export const STAR_ADD_NEW_ITEM = "add_new_item"
export const STAR_CREATE_NEW_CARD = "create_new_card"

function GuidingStar () {
    return <div className="GuidingStar">
        <div className="icon icon-guiding-star"></div>
        <div className="icon glow icon-guiding-star"></div>
    </div>
}

export default GuidingStar