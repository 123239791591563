import DetailsCardInspector from "./DetailsCardInspector"
import ItemListCardInspector from "./ItemListCardInspector"
import MusicAlbumCardInspector from "./MusicAlbumCardInspector"
import VideoCardInspector from "./VideoCardInspector"
import QRCodeCardInspector from "./QRCodeCardInspector"
import CardListCardInspector from "./CardListCardInspector"
import LandingCardInspector from "./LandingCardInspector"
import ImagesCardInspector from "./ImagesCardInspector"
import PricingListCardInspector from "./PricingListCardInspector"
import ContactCardInspector from "./ContactCardInspector"

const registerInspectors = (inspectors) => {
	const registry = {}
	inspectors.forEach(inspector => registry[inspector.type] = inspector)
	return registry
}

export const canCreateInspectors = [
	LandingCardInspector,
	CardListCardInspector,
	PricingListCardInspector,
	DetailsCardInspector,
	ContactCardInspector,
	ImagesCardInspector,
	ItemListCardInspector,
	QRCodeCardInspector
]

export const allInspectors = canCreateInspectors.concat([
	VideoCardInspector,
	MusicAlbumCardInspector
])

export const inspectorsRegistry = registerInspectors(allInspectors)

export const createCard = cardType => inspectorsRegistry[cardType].getDefaultHash()