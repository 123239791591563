import React, { useState } from "react"

export default function RevealableField ({ children, show, label }) {
	const [ revealed, setRevealed ] = useState(show)

	if (revealed || show) {
		return children
	}

	return <button className="RevealableField" onClick={() => setRevealed(true)}>
		{ label }
	</button>
}