import React from 'react'
import ReactDOM from 'react-dom'
import App from './App'
import './index.css'
import rootReducer from './reducers'
import { createStore } from 'redux'
import { Provider } from 'react-redux'
import * as Sentry from "@sentry/react"
import { Integrations } from "@sentry/tracing"
import { isProduction } from './util/server'

if (isProduction) {
	Sentry.init({
		dsn: "https://b4dfede6b355463ca7178d7485a7656e@o511240.ingest.sentry.io/5608099",
		integrations: [
			new Integrations.BrowserTracing()
		],
		tracesSampleRate: 1.0
	})
}

export const store = createStore(
	rootReducer,
	window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__()
)

ReactDOM.render(
	<Provider store={store}>
		<App />
	</Provider>,
	document.getElementById('root')
)
