import React from "react"
import PropTypes from "prop-types"
import { useRef } from "react"
import "./TextAreaInput.scss"

function TextAreaInput (props) {
	const changeTimeout = useRef()

	const onChange = e => {
		const value = e.target.value
		clearInterval(changeTimeout.current)
		changeTimeout.current = setTimeout(() => props.onChange(value), 800)
	}

	return <textarea className="TextAreaInput" onChange={onChange} defaultValue={props.value} />
}

TextAreaInput.propTypes = {
	value: PropTypes.string,
	onChange: PropTypes.func
}

export default TextAreaInput