import React from "react"
import PropTypes from "prop-types"
import "./ShareOptions.scss"
import { t } from "../../intl"
import QRCode from "./QRCode"

function ShareOptions ({ publishedUrl, qrCodeFilename }) {
	const prettyPublishedUrl = () => {
		return publishedUrl
			.replace("http://", "")
			.replace("https://", "")
	}

	return <div className="ShareOptions popup-component-support">
		<div className="label">{ t("publicLink") }</div>
		<a href={publishedUrl} rel="external noopener noreferrer" target="_blank">{ prettyPublishedUrl() }</a>
		<div className="label">{ t("qrCode") }</div>
		<div className="qr-code">
			<QRCode filename={qrCodeFilename}>{ publishedUrl }</QRCode>
		</div>
	</div>
}

ShareOptions.propTypes = {
	publishedUrl: PropTypes.string.isRequired,
	qrCodeFilename: PropTypes.string
}

export default ShareOptions