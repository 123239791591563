import React, { useCallback } from "react"
import PropTypes from "prop-types"
import { useDispatch } from "react-redux"
import CardList from "./GUI/CardList"
import { t } from "../intl"
import { canCreateInspectors } from "./Inspector/cardInspectors"

function CardCreate (props) {
	const { onNewCard, className, value, guidingStarLocations } = props
	const dispatch = useDispatch()

	const onCardSelect = useCallback(card => {
		onNewCard(card.type)
		dispatch({ type: "UI/CLOSE_POPUP" })
	}, [onNewCard, dispatch])

	const getCardList = () => {
		return canCreateInspectors.map(inspector => {
			const cardHash = inspector.getPlateHash(null, t(inspector.type))
			cardHash.description = t(`${inspector.type}_desc`)
			cardHash.guidingStar = guidingStarLocations && guidingStarLocations.includes(inspector.type)
			return cardHash
		})
	}

	const onClick = e => {
		const yRate = e.pageY / window.innerHeight
		dispatch({
			type: "UI/OPEN_POPUP",
			settings: {
				style: {
					top: "15px",
					bottom: "15px",
					right: `${window.innerWidth - e.pageX + 5}px`,
					transformOrigin: `right ${yRate * 100}%`
				},
			},
			component: CardList,
			props: {
				view: "list",
				cards: getCardList(),
				value,
				onChange: onCardSelect,
				fullyClickable: true
			}
		})
	}

	return <button className={`CardCreate ${className}`} onClick={onClick}>
		{ props.children || t("createNewCard") }
	</button>
}

CardCreate.propTypes = {
	className: PropTypes.string,
	onNewCard: PropTypes.func.isRequired
}

export default CardCreate