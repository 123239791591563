import cardData from "./cardData"
import { t } from "../intl"
import { compact } from "lodash-es"

const getName = ({ namePreset, name, type }) => {
	return namePreset ? t(namePreset) : (name || t(type))
}

const getIcon = ({ type, namePreset }) => {
	if (type === "website_profile" || type === "messaging") {
		return namePreset
	} else if (type === "external_link") {
		return "url"
	} else {
		return type
	}
}

const getValue = ({ type, value, street, city, state, country }) => {
	if (value) {
		return value
	}
	switch (type) {
		case "address":
			return compact([street, city, state, country]).join(", ")
		default:
			return value
	}
}

export default function contactData (data) {
	data = cardData(data)
	const fields = data.fields && data.fields
		.filter(action => action.type)
		.map(action => ({
			type: action.type,
			name: getName(action),
			value: getValue(action),
			icon: getIcon(action),
			action
		}))
	return {
		...data,
		fields
	}
}