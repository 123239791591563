import React, { Component } from "react"
import PropTypes from "prop-types"
import { t } from "../../intl"

export default class DraggableListItem extends Component {
	static propTypes = {
		dragged: PropTypes.bool,
		dragAnywhere: PropTypes.bool,
		offset: PropTypes.number,
		threshold: PropTypes.number,
		onDelete: PropTypes.func,
		onDuplicate: PropTypes.func,
		newlyCreated: PropTypes.bool,
		onContextMenu: PropTypes.func
	}

	state = {
		showActions: false
	}

	constructor (props) {
		super(props)
		this.onMouseMove = this.onMouseMove.bind(this)
		this.onMouseUp = this.onMouseUp.bind(this)
	}

	onMouseDown (e) {
		if (e.button !== 0) {
			return
		}
		e.preventDefault()

		if (!this.props.threshold) {
			this.props.onDragStart(e)
		} else {
			if (e.touches) {
				this.startPos = {
					x: e.touches[0].pageX,
					y: e.touches[0].pageY
				}
			} else {
				this.startPos = {
					x: e.clientX,
					y: e.clientY
				}
			}
			window.addEventListener("mousemove", this.onMouseMove)
			window.addEventListener("touchmove", this.onMouseMove, { passive: false })
			window.addEventListener("mouseup", this.onMouseUp)
			window.addEventListener("touchend", this.onMouseUp)
		}
	}

	onMouseMove (e) {
		let x, y
		if (e.touches) {
			x = e.touches[0].pageX
			y = e.touches[0].pageY
		} else {
			x = e.clientX
			y = e.clientY
		}
		if (Math.abs(x - this.startPos.x) > this.props.threshold || Math.abs(y - this.startPos.y) > this.props.threshold) {
			this.endThresholdDrag()
			this.props.onDragStart(e)
		}
	}

	onMouseUp () {
		this.endThresholdDrag()
	}

	endThresholdDrag () {
		window.removeEventListener("mousemove", this.onMouseMove)
		window.removeEventListener("touchmove", this.onMouseMove, { passive: false })
		window.removeEventListener("mouseup", this.onMouseUp)
		window.removeEventListener("touchend", this.onMouseUp)
	}

	onContextMenu (e) {
		e.preventDefault()
		e.stopPropagation()
		if (this.props.onContextMenu) {
			this.props.onContextMenu(e)
		} else {
			this.setState({ showActions: true })
		}
	}

	onMouseLeave () {
		this.setState({ showActions: false })
	}

	render () {
		let transforms = []
		if (this.props.offset !== 0) {
			transforms.push(`translateY(${this.props.offset}px)`)
		}
		let style = { transform: transforms.join(' ') }
		const hasActions = this.props.onDelete || this.props.onDuplicate
		return <div
			className={`DraggableListItem ${this.props.newlyCreated && 'newly-created'} ${this.props.dragged && 'dragged'} ${this.props.dragAnywhere && 'drag-anywhere'}`}
			style={style}
			onMouseDown={this.props.dragAnywhere && this.onMouseDown.bind(this)}
			onContextMenu={this.onContextMenu.bind(this)}
			onMouseLeave={this.onMouseLeave.bind(this)}
		>
			<div className="handle-bar" onMouseDown={this.props.onDragStart.bind(this)} onTouchStart={this.props.onDragStart.bind(this)}>
				<div className="handle"><span className="icon icon-drag-handle"></span></div>
				<div className="title">{ this.props.children.props.title || this.props.children.props.label || this.props.children.props.itemTitle }</div>
			</div>
			{ hasActions && <div className={`actions ${this.state.showActions && "force-show"}`}>
				{ this.props.onDuplicate && <div className="action icon-duplicate" onClick={this.props.onDuplicate} title={t("duplicate")}></div> }
				{ this.props.onDelete && <div className="action icon-delete" onClick={this.props.onDelete} title={t("remove")}></div> }
			</div> }
			{ this.props.children }
		</div>
	}
}