import React, { Component, createRef } from "react"
import PropTypes from "prop-types"
import "./StackEmbed.scss"
import { differentObjectValues } from "../util"
import { Viewer_URL } from "../util/server"
import LoadingIndicator from "./GUI/LoadingIndicator"
import { t } from "../intl"

class StackEmbed extends Component {
	static propTypes = {
		stackKey: PropTypes.string,
		currentCard: PropTypes.string,
		home: PropTypes.string,
		cards: PropTypes.array,
		onGoToCard: PropTypes.func
	}

	constructor (props) {
		super(props)
		this.state = {
			loading: true,
			largePreview: false
		}
		this.iframeRef = createRef()
		this.onReceiveMessage = this.onReceiveMessage.bind(this)
		this.embedMounted = false
	}

	onReceiveMessage (e) {
		// console.log("(designer) StackEmbed onReceiveMessage", e.data)
		if (e.data.type === "event") {
			switch(e.data.event) {
				case "mounted":
					this.embedMounted = true
					this.setState({ loading: false })
					this.refreshIframe({})
					break
				case "goToCard":
					if (e.data.cardId !== this.props.currentCard) {
						this.props.onGoToCard(e.data.cardId)
						this.embedCurrentCard = this.props.currentCard
					}
					break
				default:
			}
		}
	}
	
	getSrc () {
		return this.props.src || Viewer_URL // || (this.props.stackKey ? `${Viewer_URL}/${this.props.stackKey}` : null)
	}

	componentDidMount () {
		window.addEventListener("message", this.onReceiveMessage, false)
	}

	componentDidUpdate (prevProps) {
		this.refreshIframe(prevProps)
	}

	componentWillUnmount () {
		window.removeEventListener("message", this.onReceiveMessage, false)
	}

	refreshIframe (prevProps) {
		if (!this.embedMounted || !this.props.currentCard) {
			return
		}
		const payload = {}
		if (differentObjectValues(this.props.cards, prevProps.cards)) {
			payload.cards = this.props.cards
		}
		if (this.props.currentCard !== prevProps.currentCard) {
			payload.currentCard = this.props.currentCard
		}
		if (this.props.home !== prevProps.home) {
			payload.home = this.props.home
		}
		if (Object.keys(payload).length > 0) {
			this.iframeRef.current.contentWindow.postMessage(payload, this.getSrc())
		}
	}

	onPreviewOpen () {
		this.setState({ largePreview: true })
	}

	onPreviewClose () {
		this.setState({ largePreview: false })
	}

	render () {
		return <div className={`StackEmbed ${this.state.largePreview && "fullscreen"} ${this.state.loading && "loading"}`}>
			<div className="exit-fullscreen" onClick={this.onPreviewClose.bind(this)}>
				{ t("exit_preview_fullscreen") }
			</div>
			<div className="center-overlay">
				<LoadingIndicator visible={this.state.loading} />
			</div>
			<div className="container" onClick={this.onPreviewOpen.bind(this)}>
				<iframe title="Stack" frameBorder={0} src={this.getSrc()} ref={this.iframeRef} />
			</div>
		</div>
	}
}

export default StackEmbed