export default ({ url, width, height, resizeType }) => {
	const createHmac = require('create-hmac')

	const key = "9ed751583655b951d3b07c03b07523ca8d1365106d63eab3b17afbca88d58634"
	const salt = "d3c4cb2b8452eb508d72f26cd265989b9712468f89c132376d491cb41c29c6bc"
	
	const urlSafeBase64 = (string) => {
		return Buffer.from(string).toString('base64').replace(/=/g, '').replace(/\+/g, '-').replace(/\//g, '_')
	}

	const hexDecode = (hex) => Buffer.from(hex, 'hex')

	const sign = (salt, target, secret) => {
		const hmac = createHmac('sha256', hexDecode(secret))
		hmac.update(hexDecode(salt))
		hmac.update(target)
		return urlSafeBase64(hmac.digest())
	}

	if (!resizeType) resizeType = "fit"
	if (!height) height = width
	const gravity = "no"
	const enlarge = 1
	const encoded_url = urlSafeBase64(url)
	const path = `/${resizeType}/${width}/${height}/${gravity}/${enlarge}/${encoded_url}`

	const signature = sign(salt, path, key)
	const result = `${signature}${path}`
	
	return `https://stacky-images.herokuapp.com/${result}`
}