import React, { useEffect, useState, useCallback } from "react"
import PropTypes from "prop-types"
import QRCodeLib from "qrcode"
import "./QRCode.scss"
import { t } from "../../intl"

const defaultQRSettings = {
	errorCorrectionLevel: "M",
	type: "image/png",
	margin: 2,
	scale: 8,
	color: {
		dark: "#111",
		light: "#fff"
	}
}

function QRCode ({ children, filename }) {	
	const [ qrCode, setQRCode ] = useState(null)

	const generateQRCode = useCallback(async (text) => {
		const dataURL = await QRCodeLib.toDataURL(text, defaultQRSettings)
		setQRCode(dataURL)
	}, [setQRCode])

	useEffect(() => {
		generateQRCode(children)
	}, [children, generateQRCode])

	return <a download={`${filename || t("qrCode")}.png`} className="QRCode" href={qrCode} target="_blank" rel="noopener noreferrer">
		<img src={qrCode} alt={children} />
	</a>
}

QRCode.propTypes = {
	children: PropTypes.string
}

export default QRCode