import cardData from "./cardData"
import contactData from "./contactData"

const dataProcessors = {
	contact: contactData
}

const bodyFromBodyHTML = (data) => {
	if (data.bodyHTML !== undefined) {
		const tempDiv = document.createElement("div")
		tempDiv.innerHTML = data.bodyHTML
		data.body = tempDiv.textContent || tempDiv.innerText || ""
	}
	return data
}

export default (type, data) => {
	const processor = dataProcessors[type] || cardData

	bodyFromBodyHTML(data)

	return processor(data)
}