import React, { useRef } from "react"
import { useDispatch, useSelector } from "react-redux"
import PropTypes from "prop-types"
import "./Popup.scss"
import { useEffect } from "react"

function Popup (props) {
	const { visible, settings } = props
	const uiSize = useSelector(state => state.ui.size)
	const dispatch = useDispatch()
	const timeout = useRef(null)

	const getComponent = () => {
		if (props.component) {
			const Component = props.component
			return <Component {...props.props} />
		}
		return null
	}

	const onBackdropClick = () => {
		dispatch({ type: "UI/CLOSE_POPUP" })
		if (settings.onClose) {
			settings.onClose()
		}
		clearTimeout(timeout.current)
		timeout.current = setTimeout(() => {
			dispatch({ type: "UI/CLEAR_POPUP" })
		}, 250)
	}

	useEffect(() => {
		if (visible === true) {
			clearTimeout(timeout.current)
		}
	}, [visible])

	return <div className={`Popup ${visible && "visible"} ${settings.preset && settings.preset}`}>
		<div className="backdrop" onClick={onBackdropClick}></div>
		<div className="container" style={uiSize === "large" ? settings.style : null}>{ getComponent() }</div>
	</div>
}

Popup.propTypes = {
	component: PropTypes.func,
	settings: PropTypes.object.isRequired,
	props: PropTypes.object.isRequired,
	visible: PropTypes.bool.isRequired
}

export default Popup