import React from "react"
import { t } from "../../intl"
import TextInput from "../GUI/TextInput"
import ImageInput from "../GUI/ImageInput"
import CardInspector from "./CardInspector"
import OptionsInput from "../GUI/OptionsInput"
import EditableListField from "./EditableListField"
import CallsToAction from "./Bits/CallsToAction"
import RichTextInput from "../GUI/RichTextInput"
import RevealableField from "./RevealableField"

export default class DetailsCardInspector extends CardInspector {
	renderFields () {
		return <>
			<EditableListField
				label={t("images")}
				items={this.props.data.images || []}
				newItemCaption={t("addNewImage")}
				newItemTemplate={{ url: null }}
				onChange={items => this.onListChange("images", items)}
				itemTitle={({ index }) => t("image_nx", { x: index + 1 })}
				component={Image}
			>
				<div className="field">
					<label>{ t("imagesRatio") }</label>
					<OptionsInput
						options={["wide", "square"]}
						value={this.props.data.imagesRatio || "wide"}
						onChange={imagesRatio => this.props.onDataChange({ imagesRatio })}
					/>
				</div>
			</EditableListField>
			<div className="field">
				<label>{ t("body") }</label>
				<RichTextInput
					// [migration-3] Top level "body" becomes "bodyHTML" if it's empty. "body" gets deleted.
					value={this.props.data.bodyHTML || this.props.data.body}
					onChange={bodyHTML => this.props.onDataChange({ bodyHTML })}
					placeholder={ t("type_here") }
				/>
			</div>
			<RevealableField label={t("add_object", { object: t("shortDescription") })} show={this.props.data.description}>
				<div className="field">
					<label>{ t("shortDescription") }</label>
					<TextInput
						value={this.props.data.description}
						onChangeFinal={description => this.props.onDataChange({ description })}
						escCancelsEdit={true}
					/>
					<div className="hint">{ t("cardShortDescriptionHint") }</div>
				</div>
			</RevealableField>
			<CallsToAction
				alwaysShow={true}
				onListChange={this.onListChange.bind(this)}
				callsToAction={this.props.data.callsToAction}
				guidingStarLocations={this.props.guidingStarLocations.cta}
			/>
		</>
	}

	static type = "details"

	static getDefaultHash (cardId, cardName) {
		const props = super.getDefaultHash(cardId, cardName)
		props.data = {
			body: t("thisIsTheBody"),
			callsToAction: []
		}
		return props
	}

	static getAPISampleHash (cardName) {
		const props = super.getAPISampleHash(cardName)
		props.data = {
			title: "My details card",
			body: t("thisIsTheBody"),
			callsToAction: [
				{
					label: t("images"),
					action: {
						type: "card",
						cardId: "6fe3fc682a8b00bae9a863e54d1b8d1a"
					}
				},
				{
					label: "Show THE image",
					action: {
						type: "card",
						cardId: "256cfbf31c3da67779300204e10052bf",
						params: "imageId=image%20on%20my%20server.jpg"
					}
				},
				{
					label: "Stacky website",
					action: {
						type: "external",
						url: "https://stacky.app/"
					}
				}
			],
			imagesRatio: "wide",
			images: [
				{
					url: "http://mydomain.com/myimage.jpg"
				},
				{
					url: "http://mydomain.com/myimage.jpg"
				}
			]
		}
		return props
	}
}

const Image = props => {
	const { index, url, onListChange } = props
	return <div className="field">
		<ImageInput
			value={url}
			onChange={url => onListChange(index, { url }) }
		/>
	</div>
}