import React from "react"
import { t } from "../../../intl"
import TextInput from "../../GUI/TextInput"

export default function AddressInput ({ onChange, street, city, state, country }) {
	return <>
		<TextInput
			value={street}
			placeholder={t("street")}
			onChangeFinal={street => onChange({ street })}
			escCancelsEdit={true}
		/>
		<TextInput
			value={city}
			placeholder={t("city")}
			onChangeFinal={city => onChange({ city })}
			escCancelsEdit={true}
		/>
		<TextInput
			value={state}
			placeholder={t("state")}
			onChangeFinal={state => onChange({ state })}
			escCancelsEdit={true}
		/>
		<TextInput
			value={country}
			placeholder={t("country")}
			onChangeFinal={country => onChange({ country })}
			escCancelsEdit={true}
		/>
	</>
}