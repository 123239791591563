import React, { useCallback, useEffect } from "react"
import PropTypes from "prop-types"
import { useDispatch, useSelector } from "react-redux"
import CardList from "./GUI/CardList"
import { t } from "../intl"
import CardPreview from "./GUI/CardPreview"
import "./CardSelect.scss"
import CardCreate from "./CardCreate"
import { createCard } from "./Inspector/cardInspectors"
import { useState } from "react"
import GuidingStar, { STAR_CREATE_NEW_CARD } from "./GUI/GuidingStar"

function CardSelect (props) {
	const { onChange, value, createCardType } = props
	const guidingStarLocations = props.guidingStarLocations || []
	const [ newlySelected, setNewlySelected ] = useState(false)
	const cards = useSelector(state => state.stack.cards)
	const currentCardLock = useSelector(state => state.ui.currentCardLock)
	const dispatch = useDispatch()

	const getCard = (id) => {
		return cards.find(card => card.id === id)
	}

	const selectedCard = getCard(value)

	const onListChange = useCallback(card => {
		onChange(card.id)
		dispatch({ type: "UI/CLOSE_POPUP" })
	}, [onChange, dispatch])

	const onCreateCard = async cardType => {
		setNewlySelected(true)
		const card = createCard(cardType)
		await dispatch({
			type: "STACK/ADD_CARD",
			card
		})
		onChange(card.id)
	}

	const onChooseCard = e => {
		const yRate = e.pageY / window.innerHeight
		dispatch({
			type: "UI/OPEN_POPUP",
			settings: {
				style: {
					top: "15px",
					bottom: "15px",
					right: `${window.innerWidth - e.pageX + 15}px`,
					transformOrigin: `right ${yRate * 100}%`
				},
			},
			component: CardList,
			props: {
				view: "list",
				cards,
				value,
				onChange: onListChange,
				fullyClickable: true,
				cardTypes: createCardType ? [createCardType] : undefined
			}
		})
	}

	const onGoToCard = () => {
		if (currentCardLock > 0) return
		dispatch({
			type: "STACK/SET_CURRENT_CARD",
			currentCard: selectedCard.id
		})
	}
	
	useEffect(() => {
		dispatch({
			type: "UI/UPDATE_POPUP_PROPS",
			props: {
				value
			}
		})
	}, [value, dispatch])

	return <div className={`CardSelect ${!selectedCard && "empty"}`}>
		{ selectedCard
			? <div className={`card ${newlySelected && "newly-selected"}`}>
				<div className="preview">
					<CardPreview {...selectedCard} />
				</div>
				<div className="description" onClick={onGoToCard}>
					<div className="name">{ selectedCard.name || selectedCard.data.title }</div>
					<div className="hint">{ t("clickToGoToCard") }</div>
				</div>
				<div className="edit" onClick={onChooseCard} title={ t("clickToChange") }>
					<div className="icon icon-edit"></div>
				</div>
			</div>
			: <div className="actions-list">
				{ createCardType
					? <button className="floaty" onClick={() => onCreateCard(createCardType)}>{ guidingStarLocations.includes(STAR_CREATE_NEW_CARD) ? <GuidingStar /> : null }{ t("newXCard", { x: t(createCardType) }) }</button>
					: <CardCreate className="floaty" onNewCard={onCreateCard} guidingStarLocations={guidingStarLocations}>{ guidingStarLocations.includes(STAR_CREATE_NEW_CARD) ? <GuidingStar /> : null }{ t("createNewCard") }</CardCreate>
				}
				<button className="floaty" disabled={cards.length <= 1} onClick={onChooseCard}>{ t("chooseExistingCard") }</button>
			</div>
		}
	</div>
}

CardSelect.propTypes = {
	value: PropTypes.string,
	onChange: PropTypes.func.isRequired
}

export default CardSelect