import React from 'react'
import TextInput from '../GUI/TextInput'
import ImageInput from '../GUI/ImageInput'
import CardInspector from './CardInspector'
import ActionInput from '../GUI/ActionInput'
import { t } from '../../intl'
import EditableListField from './EditableListField'
import CallsToAction from './Bits/CallsToAction'

export default class ItemListCardInspector extends CardInspector {
	renderFields () {
		return <>
			<EditableListField
				label={t("items")}
				items={this.props.data.items}
				newItemTemplate={{ label: null, link: null }}
				onChange={items => this.onListChange("items", items)}
				itemTitle={({ index }) => t("list_item_nx", { x: index + 1 })}
				component={Item}
			/>
			<CallsToAction
				alwaysShow={false}
				onListChange={this.onListChange.bind(this)}
				callsToAction={this.props.data.callsToAction}
			/>
		</>
	}

	static type = "itemList"

	static getDefaultHash (cardId, cardName) {
		const props = super.getDefaultHash(cardId, cardName)
		props.data = {
			items: []
		}
		return props
	}

	static getAPISampleHash (cardName) {
		const props = super.getAPISampleHash(cardName)
		props.data = {
			title: "My items",
			items: [
				{
					title: "Fishes",
					description: "Pretty fishes.",
					image: "http://mydomain.com/myimage.jpg"
				},
				{
					title: "No image",
					description: "No pretty fishes.",
					action: {
						type: "card",
						cardId: "256cfbf31c3da67779300204e10052bf"
					}
				}
			]
		}
		return props
	}
}

const Item = props => {
	const { index, onListChange, image, title, description, action } = props
	return <div className="field">
		<div className="columns">
			<div className="column">
				<div className="label">
					{ t("image") }
					{ image && <button className="field-action free-floating" onClick={() => onListChange(index, { image: null })}>{ t("remove") }</button> }
				</div>
				<ImageInput
					value={image}
					onChange={image => onListChange(index, { image })}
				/>
			</div>
			<div className="column wide">
				<label>{ t("title") }</label>
				<TextInput
					value={title}
					onChangeFinal={title => onListChange(index, { title })}
					escCancelsEdit={true}
				/>
				<label>{ t("shortDescription") }</label>
				<TextInput
					value={description}
					onChangeFinal={description => onListChange(index, { description })}
					escCancelsEdit={true}
				/>
			</div>
		</div>
		<label>{ t("actionOnClick") }</label>
		<ActionInput
			{...action}
			onChange={action => onListChange(index, { action })}
		/>
	</div>
}
