import React, { Component, createRef } from "react"
import "./ImageInput.scss"
import { Server_URL } from "../../util/server"
import PropTypes from "prop-types"
import LoadingIndicator from "./LoadingIndicator"
import { t } from "../../intl"
import SmartImage from "./SmartImage"
import { connect } from "react-redux"

const imageUploadURL = `${Server_URL}/tools/imageupload`
const fileTypes = ["image/png", "image/apng", "image/jpg", "image/jpeg", "image/gif"]

class ImageInput extends Component {
	state = {
		draggedOver: false,
		uploading: false,
		fileSrc: null,
		fileSize: null,
		iframeIteration: 0
	}

	constructor (props) {
		super(props)
		this.onReceiveMessage = this.onReceiveMessage.bind(this)
		this.iframeRef = createRef()
	}

	componentDidMount () {
		window.addEventListener("message", this.onReceiveMessage)
	}

	componentWillUnmount () {
		window.removeEventListener("message", this.onReceiveMessage)
	}

	validateFileSize (size) {
		return size <= 5 * 1024 * 1024
	}

	validateFileType (type) {
		return fileTypes.includes(type)
	}

	submitFileUpload () {
		this.iframeRef.current.contentWindow.postMessage({ type: "submit" }, imageUploadURL)
	}

	resetFileUpload () {
		this.iframeRef.current.contentWindow.postMessage({ type: "reset" }, imageUploadURL)
	}

	onReceiveMessage (e) {
		if (e.data.type === "fileFrameEvent" && e.source === this.iframeRef.current.contentWindow) {
			const { payload } = e.data
			switch (payload.event) {
				case "dragEnter":
					this.setState({ draggedOver: true })
					break
				case "dragLeave":
					this.setState({ draggedOver: false })
					break
				case "badFile":
					if (!this.validateFileType(payload.file.type)) {
						alert(t("error_file_type_not_supported"))
						this.resetFileUpload()
						return
					}
					break
				case "fileUploading":
					if (!this.validateFileSize(payload.file.size)) {
						alert(t("error_image_larger_than_maxSize", { maxSize: "5 MB" }))
						this.resetFileUpload()
						return
					}
					if (!this.validateFileType(payload.file.type)) {
						alert(t("error_file_type_not_supported"))
						this.resetFileUpload()
						return
					}
					this.submitFileUpload()
					if (!this.state.uploading) {
						this.props.dispatch({ type: "UI/LOCK_CURRENT_CARD" })
					}
					this.setState({
						draggedOver: false,
						uploading: true,
						fileSrc: payload.localUrl,
						fileSize: payload.fileSize
					})
					break
				case "fileUploaded":
					this.props.dispatch({ type: "UI/UNLOCK_CURRENT_CARD" })
					this.setState({
						draggedOver: false,
						uploading: false,
						fileSrc: null,
						fileSize: null,
						iframeIteration: this.state.iframeIteration + 1
					})
					this.props.onChange(payload.url)
					break
				default:
			}
		}
	}

	onMouseOver () {
		this.setState({ hover: true })
	}

	onMouseOut () {
		this.setState({ hover: false })
	}

	getLabel () {
		if (this.state.uploading) {
			return null
		}
		if (this.props.value) {
			return this.state.draggedOver
				? t("dropImageToReplace")
				: (this.state.hover ? t("clickToReplaceImage") : "")
		} else {
			return this.state.draggedOver
				? t("dropImageToInsert")
				: t("dropImageOrChooseOne")
		}
	}

	render () {
		const dropLabel = this.getLabel()
		const classNames = ["ImageInput"]
		if (this.state.draggedOver) classNames.push("dragged-over")
		if (this.state.hover) classNames.push("hover")
		if (this.props.value) classNames.push("has-value")
		if (this.state.uploading) classNames.push("uploading")
		return <div className={classNames.join(" ")} onMouseOver={this.onMouseOver.bind(this)} onMouseOut={this.onMouseOut.bind(this)}>
			<iframe key={`iframe-${this.state.iframeIteration}`} ref={this.iframeRef} src={imageUploadURL} title={t("fileUpload")}></iframe>
			<div className="dropzone-cover">
				{ this.props.value && !this.state.uploading && <SmartImage url={this.props.value} size="medium" /> }
				{ this.state.fileSrc && <div id="previewImage" className="preview" style={{ backgroundImage: `url("${this.state.fileSrc}")` }}></div> }
				<div className="outline"></div>
				{ dropLabel && <div className="drop-label">{ dropLabel }</div> }
			</div>
			<div className="center-overlay">
				<LoadingIndicator visible={this.state.uploading} />
			</div>
		</div>
	}
}

ImageInput.propTypes = {
	value: PropTypes.string,
	onChange: PropTypes.func.isRequired,
	dispatch: PropTypes.func.isRequired
}

export default connect()(ImageInput)