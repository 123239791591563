const DEFAULT_STATE = {
	token: null,
	stacks: []
}

const getStack = (stacks, stackKey) => {
	return stacks.findIndex(stack => stack.key === stackKey)
}

const session = (state = DEFAULT_STATE, action) => {
	let stackIndex
	let stacks
	switch (action.type) {
		case 'SESSION/SET_TOKEN':
			return {
				...state,
				token: action.payload
			}
		case 'SESSION/SET_STACKS':
			return {
				...state,
				stacks: action.payload
			}
		case 'SESSION/ADD_STACK':
			return {
				...state,
				stacks: [action.stack].concat(state.stacks)
			}
		case 'SESSION/UPDATE_STACK_KEY':
			stacks = state.stacks.concat()
			stackIndex = getStack(stacks, action.stackKey)
			if (stackIndex < 0) {
				console.error(action)
				return state
			}
			stacks[stackIndex] = {
				...stacks[stackIndex],
				key: action.newKey
			}
			return {
				...state,
				stacks
			}
		case 'SESSION/UPDATE_STACK_HOME':
			stacks = state.stacks.concat()
			stackIndex = getStack(stacks, action.stackKey)
			if (stackIndex < 0) {
				console.error(action)
				return state
			}
			stacks = state.stacks.concat([])
			stacks[stackIndex].home_card = action.homeCard
			return {
				...state,
				stacks
			}
		case 'SESSION/BUMP_STACK_TO_FIRST':
			stacks = state.stacks.concat()
			stackIndex = getStack(stacks, action.stackKey)
			if (stackIndex < 0) {
				console.error(action)
				return state
			}
			stacks = state.stacks.concat([])
			let stack = stacks[stackIndex]
			stacks.splice(stackIndex, 1)
			stacks.unshift(stack)
			return {
				...state,
				stacks
			}
		default:
			return state
	}
}

export default session