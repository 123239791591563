import { combineReducers } from 'redux'
import account from './account'
import session from './session'
import stack from './stack'
import ui from './ui'

export default combineReducers({
	account,
	session,
	stack,
	ui
})