import React from 'react'
import ClipboardInput from '../GUI/ClipboardInput'
import CardInspector from './CardInspector'

export default class VideoCardInspector extends CardInspector {
	renderFields () {
		return <>
			<div className="field">
				<label>YouTube link</label>
				<ClipboardInput
					value={this.props.data.shareLink}
					onChange={shareLink => this.props.onDataChange({ shareLink })}
					pasteInstructions={"Please paste a YouTube video link"}
				/>
			</div>
		</>
	}

	static type = "video"

	static getDefaultHash (cardId, cardName) {
		const props = super.getDefaultHash(cardId, cardName)
		props.data = {
			shareLink: ""
		}
		return props
	}

	static getAPISampleHash (cardName) {
		const props = super.getAPISampleHash(cardName)
		props.data = {
			shareLink: "https://www.youtube.com/watch?v=dQw4w9WgXcQ"
		}
		return props
	}
}
