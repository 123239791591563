import React from "react"
import { useSelector } from "react-redux"
import PropTypes from "prop-types"
import "./CardPreview.scss"
import processData from "../../dataProcessors"
import SmartImage from "./SmartImage"

function CardPreview (props) {
	const cards = useSelector(state => state.stack.cards)

	const getCard = (id) => {
		return cards.find(card => card.id === id) || {}
	}

	const { name, type, onClick, showPlate } = props
	const data = showPlate ? {} : processData(type, props.data)
	const { body, items, fields } = data
	const title = data.title || name
	const showTitle = !showPlate || type !== "landing"
	const image = data.image || (data.images && data.images[0] && data.images[0].url)
	const withBottomFade = showPlate ? "" : "with-bottom-fade"
	
	return <div className={`CardPreview ${type} ${withBottomFade} ${onClick && "clickable"}`}>
		{ onClick && <div className="clickable-surface" onClick={onClick}></div> }
		{ showPlate &&
			<div className="plate" style={{ backgroundImage: `url("/card-plates/${type}@2x.png")` }}></div>
		}
		{ showTitle && title && <div className="title">{ title }</div> }
		{ image && <SmartImage url={image} size="small" /> }
		{ body && <div className="body">{ body }</div> }
		{ items && <div className="items">
			{ items.slice(0, 5).map((item, i) => {
				const title = item.cardId ? getCard(item.cardId).name : item.title
				return <div className="item" key={i}>{ title }</div>
			})}
			{ items.length > 5 && <div className="item" key="more">...</div> }
		</div> }
		{ fields && <dl className="definitions">
			{ fields.slice(0, 5).map((item, i) => {
				return <div className="item" key={i}>
					<dt>{ item.name }</dt>
					<dd>{ item.value }</dd>
				</div>
			})}
			{ fields.length > 3 && <div className="item" key="more">...</div> }
		</dl> }
	</div>
}

CardPreview.propTypes = {
	type: PropTypes.string.isRequired,
	data: PropTypes.object,
	onClick: PropTypes.func
}

export default CardPreview