import React from "react"
import CardInspector from "./CardInspector"
import { t } from "../../intl"
import CardSelect from "../CardSelect"
import EditableListField from "./EditableListField"
import CallsToAction from "./Bits/CallsToAction"
import BannerInput from "./Bits/BannerInput"
import RevealableField from "./RevealableField"
import RichTextInput from "../GUI/RichTextInput"
import TextInput from "../GUI/TextInput"
import ToggleInput from "../GUI/ToggleInput"

export default class CardListCardInspector extends CardInspector {
	renderFields () {
		const { banner, bodyHTML, body } = this.props.data
		return <>
			<RevealableField label={t("add_object", { object: t("banner") })} show={banner}>
				<div className="field">
					<BannerInput
						banner={banner}
						onDataChange={this.props.onDataChange}
					/>
				</div>
			</RevealableField>
			<RevealableField label={t("add_object", { object: t("body") })} show={bodyHTML || body}>
				<div className="field">
					<label>{ t("body") }</label>
					<RichTextInput
						// [migration-3] Top level "body" becomes "bodyHTML" if it's empty. "body" gets deleted.
						value={bodyHTML || body}
						onChange={bodyHTML => this.props.onDataChange({ bodyHTML })}
						placeholder={ t("type_here") }
					/>
				</div>
			</RevealableField>
			<RevealableField label={t("add_object", { object: t("shortDescription") })} show={this.props.data.description}>
				<div className="field">
					<label>{ t("shortDescription") }</label>
					<TextInput
						value={this.props.data.description}
						onChangeFinal={description => this.props.onDataChange({ description })}
						escCancelsEdit={true}
					/>
					<div className="hint">{ t("cardShortDescriptionHint") }</div>
				</div>
			</RevealableField>
			<EditableListField
				label={t("cards")}
				items={this.props.data.items}
				newItemTemplate={{ cardId: null }}
				onChange={items => this.onListChange("items", items)}
				itemTitle={({ index }) => t("card_nx", { x: index + 1 })}
				component={Item}
				guidingStarLocations={this.props.guidingStarLocations.cards}
			>
				<div className="field">
					<ToggleInput
						label={t("titles_only")}
						value={this.props.data.titlesOnly || false}
						onChange={titlesOnly => this.props.onDataChange({ titlesOnly })}
						loading={this.state.updatingSettings}
					/>
				</div>
			</EditableListField>
			<CallsToAction
				alwaysShow={false}
				onListChange={this.onListChange.bind(this)}
				callsToAction={this.props.data.callsToAction}
			/>
		</>
	}

	static type = "cardList"

	static getDefaultHash (cardId, cardName) {
		const props = super.getDefaultHash(cardId, cardName)
		props.data = {
			items: []
		}
		return props
	}

	static getAPISampleHash (cardId, cardName) {
		const props = super.getAPISampleHash(cardId, cardName)
		props.data = {
			title: "My cards",
			body: t("thisIsTheBody"),
			items: [
				{
					cardId: this.generateCardId()
				}
			]
		}
		return props
	}
}

const Item = props => {
	const { index, onListChange, cardId, guidingStarLocations } = props
	return <>
		<div className="field">
			<CardSelect
				value={cardId}
				onChange={cardId => onListChange(index, { cardId })}
				guidingStarLocations={guidingStarLocations}
			/>
		</div>
	</>
}