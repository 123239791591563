import React from "react"
import { t } from "../../../intl"
import ImageInput from "../../GUI/ImageInput"

export default function LogoInput ({ onDataChange, logo }) {
	return <>
		<label>{ t("branding_logotype") }
			{ logo && <div className="field-action" onClick={() => onDataChange({ logo: null })}>{ t("remove") }</div> }
		</label>
		<div className="columns">
			<div className="column wide">
				<ImageInput
					value={logo}
					onChange={logo => onDataChange({ logo })}
				/>
			</div>
		</div>
	</>
}