import React, { Component } from "react"
import PropTypes from "prop-types"
import { t } from "../../intl"

export default class OptionsInput extends Component {
	static propTypes = {
		options: PropTypes.arrayOf(PropTypes.string).isRequired,
		emptyLabel: PropTypes.string,
		value: PropTypes.string
	}

	onChange (e) {
		this.props.onChange(e.target.value === "" ? null : e.target.value)
	}

	render () {
		return <select onChange={this.onChange.bind(this)} value={this.props.value || ""}>
			{ !this.props.value && !this.props.emptyLabel && <option key=" " value={""}></option> }
			{ this.props.options.map(option => {
				let value, label
				if (Array.isArray(option)) {
					[value, label] = option
				} else {
					value = option
					label = option
				}
				return <option key={value} value={value}>
					{ t(label) }
				</option>
			}) }
			{ this.props.emptyLabel && <option key=" " value={""}>{ this.props.emptyLabel }</option> }
		</select>
	}
}