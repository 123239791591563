import Polyglot from "node-polyglot"
import en from "./en"
import es from "./es"

export const defaultLocale = "en"
export const formatNumber = number => new Intl.NumberFormat(defaultLocale).format(number)

const languages = {
	en,
	es
}

const browserLanguage = (navigator.language || navigator.userLanguage).split("-")[0]
export const targetLanguage = Object.keys(languages).includes(browserLanguage) ? browserLanguage : defaultLocale

export class Intl {
	constructor (locale = null) {
		this.locale = locale
		this.polyglot = new Polyglot({
			phrases: languages[this.getLocale()],
			allowMissing: false
		})
	}

	getLocale () {
		return this.locale || defaultLocale
	}

	t (key, params) {
		return this.polyglot.t(key, this.extendWithSmartCount(params))
	}

	tl (key, params) {
		let string = this.t(key, params)
		return string.charAt(0).toLowerCase() + string.slice(1)
	}

	tu (key, params) {
		let string = this.t(key, params)
		return string.charAt(0).toUpperCase() + string.slice(1)
	}

	extendWithSmartCount (params = {}) {
		if (params.smart_count !== undefined) {
				return Object.assign({}, { formatted_smart_count: formatNumber(params.smart_count) }, params)
		}
		return Object.assign({}, { smart_count: 0, formatted_smart_count: 0 }, params)
	}
}

export const intl = new Intl(targetLanguage)
export const getLocale = intl.getLocale.bind(intl)
export const t = intl.t.bind(intl)
