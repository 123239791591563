import React, { Component } from "react"
import { connect } from "react-redux"
import { axios_instance, exposeServerError } from "./util/server"
import { refreshUISize, clearStack, setStackCards, setCurrentCard, setStackHome, setAccount, setSessionToken, setSessionStacks } from "./actions"
import LoginView from "./Components/Public/LoginView"
import StackSelect from "./Components/StackSelect"
import Creator from "./Components/Creator"
import "./App.scss"
import Popup from "./Components/GUI/Popup"
import LoadingIndicator from "./Components/GUI/LoadingIndicator"
import StackCreate from "./Components/StackCreate"

const refreshVH = () => document.body.style.setProperty("--vh", `${window.innerHeight * 0.01}px`)

const preventDefault = e => e.preventDefault()

const DIALOG_CREATE_STACK = "createStack"

class App extends Component {
	state = {
		modalDialog: null,
		editingStackKey: null,
		waitingServer: true
	}

	constructor (props) {
		super(props)
		this.refreshUI = this.refreshUI.bind(this)
	}

	componentDidMount () {
		const sessionToken = localStorage.getItem("sessionToken")
		if (sessionToken) {
			this.props.setSessionToken(sessionToken)
			const account = localStorage.getItem("account")
			if (account) {
				this.props.setAccount(JSON.parse(account))
			}
		}
		this.refreshUI()
		window.addEventListener("resize", this.refreshUI)
		window.addEventListener("dragover", preventDefault)
		window.addEventListener("drop", preventDefault)
	}

	componentWillUnmount () {
		window.removeEventListener("resize", this.refreshUI)
		window.removeEventListener("dragover", preventDefault)
		window.removeEventListener("drop", preventDefault)
	}
	
	componentDidUpdate (prevProps) {
		if (!prevProps.sessionToken && this.props.sessionToken) {
			this.onSessionTokenReceived()
		}
	}

	onSessionTokenReceived () {
		this.fetchStacks()
	}

	fetchStacks () {
		this.setState({ waitingServer: true })
		axios_instance.get("/stacks/list?session_token=" + this.props.sessionToken)
			.then(response => {
				this.props.setSessionStacks(response.data.stacks_list)
			})
			.catch(e => {
				const status = e.response && e.response.status
				switch (status) {
					case 401:
						localStorage.removeItem("sessionToken")
						window.location.reload()
						break
					default:
						exposeServerError(e)
				}
			})
			.finally(() => {
				this.setState({ waitingServer: false })
			})
	}

	onCreateNewStack () {
		this.setState({ modalDialog: DIALOG_CREATE_STACK })
	}

	onCloseModalDialog () {
		this.setState({ modalDialog: null })
	}

	onStackSelect (stackKey, homeCard) {
		this.setState({ editingStackKey: stackKey })
		if (homeCard) {
			this.props.setStackHome(homeCard.id)
			this.props.setCurrentCard(homeCard.id)
			this.props.setStackCards([homeCard])
		}
	}

	onCloseStack () {
		this.setState({ editingStackKey: null })
		this.props.clearStack({})
	}

	refreshUI () {
		refreshVH()
		this.props.refreshUISize()
	}

	renderModalDialog () {
		switch (this.state.modalDialog) {
			case DIALOG_CREATE_STACK:
				return <StackCreate
					onStackOpen={this.onStackSelect.bind(this)}
					onCloseModalDialog={this.onCloseModalDialog.bind(this)}
				/>
		}
	}

	renderContents () {
		if (!this.props.sessionToken) {
			return <LoginView />
		} else if (this.state.waitingServer) {
			return <div className="PageView">
				<LoadingIndicator visible={true} />
			</div>
		} else if (!this.state.editingStackKey) {
			return <StackSelect
				stacks={this.props.stacks}
				onStackSelect={this.onStackSelect.bind(this)}
				onCreateNewStack={this.onCreateNewStack.bind(this)}
			/>
		} else {
			return <Creator
				stackKey={this.state.editingStackKey}
				onClose={this.onCloseStack.bind(this)}
			/>
		}
	}

	render () {
		const classNames = [
			"App",
			`${this.props.uiSize}-ui`
		]
		return (
			<div className={classNames.join(" ")}>
				{ this.renderContents() }
				{ this.renderModalDialog() }
				<Popup {...this.props.popup} />
			</div>
		)
	}
}

const mapStateToProps = state => ({
	sessionToken: state.session.token,
	accountUsername: state.account.username,
	stacks: state.session.stacks,
	stack: state.stack,
	popup: state.ui.popup,
	uiSize: state.ui.size,
	currentCardLock: state.ui.currentCardLock
})

const mapDispatchToProps = { refreshUISize, clearStack, setStackCards, setCurrentCard, setStackHome, setAccount, setSessionToken, setSessionStacks }

export default connect(mapStateToProps, mapDispatchToProps)(App)