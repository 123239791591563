/* eslint-disable react-hooks/rules-of-hooks */

const DEFAULT_STATE = {
	size: "large",
	preloadedResources: [],
	popup: {
		settings: {},
		component: null,
		props: {},
		visible: false
	},
	currentCardLock: 0
}

const useLargeUI = () => window.innerWidth > 870 && window.innerHeight > 600

const ui = (state = DEFAULT_STATE, action) => {
	switch (action.type) {
		case 'UI/ADD_PRELOADED_RES':
			if (state.preloadedResources.includes(action.resource)) {
				return state
			}
			return {
				...state,
				preloadedResources: state.preloadedResources.concat([action.resource])
			}
		case 'UI/OPEN_POPUP':
			const { settings, component, props } = action
			return {
				...state,
				popup: {
					settings,
					component,
					props,
					visible: true
				},
			}
		case 'UI/UPDATE_POPUP_PROPS':
			return {
				...state,
				popup: {
					...state.popup,
					props: {
						...state.popup.props,
						...action.props
					}
				},
			}
		case 'UI/CLOSE_POPUP':
			return {
				...state,
				popup: {
					...state.popup,
					visible: false
				}
			}
		case 'UI/CLEAR_POPUP':
			return {
				...state,
				popup: DEFAULT_STATE.popup
			}
		case 'UI/LOCK_CURRENT_CARD':
			return {
				...state,
				currentCardLock: state.currentCardLock + 1
			}
		case 'UI/UNLOCK_CURRENT_CARD':
			return {
				...state,
				currentCardLock: Math.max(0, state.currentCardLock - 1)
			}
		case 'UI/REFRESH_SIZE':
			return {
				...state,
				size: useLargeUI() ? "large" : "small"
			}
		default:
			return state
	}
}

export default ui