import React from "react"
import { t } from "../../../intl"
import CardSelect from "../../CardSelect"

export default function CardLink ({ onChange, cardType, cardId, guidingStarLocations }) {
	return <>
		<label>
			{ t("x_card", { x: t(cardType) }) }
			{ cardId && <button className="field-action free-floating" onClick={() => onChange(null)}>{ t("remove") }</button> }
		</label>
		<div className="columns">
			<div className="column wide">
				<CardSelect
					value={cardId}
					onChange={cardId => onChange({ cardId })}
					guidingStarLocations={guidingStarLocations}
					createCardType={cardType}
				/>
			</div>
		</div>
	</>
}