import React, { useEffect } from "react"
import PropTypes from "prop-types"
import "./SmartImage.scss"
import { useState } from "react"
import { useSelector, useDispatch } from "react-redux"
import { useCallback } from "react"
import LoadingIndicator from "./LoadingIndicator"
import imgproxy from "../../util/imgproxy"

const sizes = {
	small: 250,
	medium: 600,
	large: 1200
}

function SmartImage ({ url, size }) {
	const dispatch = useDispatch()
	const preloadedResources = useSelector(state => state.ui.preloadedResources)
	const [imageUrl, setImageUrl] = useState(url && size ? imgproxy({ url, width: sizes[size] }) : url)
	const [loaded, setLoaded] = useState(false)
	const [loading, setLoading] = useState(false)

	const onImageLoad = useCallback(() => {
		setLoaded(true)
		setLoading(false)
		dispatch({ type: "UI/ADD_PRELOADED_RES", resource: imageUrl })
	}, [imageUrl, dispatch])

	const onImageError = () => {
		setLoading(false)
	}

	const preloadImage = useCallback(() => {
		var img = new Image()
		img.addEventListener("load", onImageLoad)
		img.addEventListener("error", onImageError)
		img.src = imageUrl
		return () => {
			img.removeEventListener("load", onImageLoad)
			img.removeEventListener("error", onImageError)
		}
	}, [imageUrl, onImageLoad])

	useEffect(() => {
		if (!loaded) {
			if (preloadedResources.includes(imageUrl)) {
				setLoading(false)
				setLoaded(true)
			} else {
				setLoading(true)
				return preloadImage()
			}
		}
	}, [loaded, preloadImage, preloadedResources, imageUrl])

	useEffect(() => {
		setImageUrl(url && size ? imgproxy({ url, width: sizes[size] }) : url)
	}, [url, size])
	
	return <div className={`SmartImage ${loaded && "loaded"} ${loading && "loading"}`}>
		{ imageUrl && <>
			<div className="image" style={{ backgroundImage: `url(${imageUrl})` }}></div>
			<LoadingIndicator visible={loading} />
		</> }
	</div>
}

SmartImage.propTypes = {
	url: PropTypes.string,
	size: PropTypes.oneOf(Object.keys(sizes))
}

export default SmartImage